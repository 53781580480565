@use "../variables";

.footer_container {
  max-width: 100vw;
  background: variables.$primary_black;
  position: sticky !important;

  p {
    padding: 0 0 54px 54px;
    color: variables.$primary_white;
  }

  .contact_bar {
    border-top: 1px solid variables.$primary_white;
    border-bottom: 1px solid variables.$primary_white;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    .contact_btn_container {
      display: flex;
      padding: 24px 54px;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;

      .ancor_tag {
        font-size: variables.$h5_font_size;
        font-weight: variables.$font_weight_light;
        letter-spacing: variables.$h5_letter_spacing;
        color: variables.$primary_white;
      }

      svg {
        cursor: pointer;
        color: variables.$primary_white;
      }
    }

    .newsletter_footer_signup {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
      max-width: 50%;
      width: 100%;

      .shipping_address_input {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 10px 24px;
        width: 100%;

        form {
          position: relative;
          width: 100%;
          input {
            font-size: variables.$p_font_size;
            color: variables.$primary-black;
            border-top: 1px solid variables.$primary-white;
            border-right: 3px solid variables.$primary-white;
            border-left: 1px solid variables.$primary-white;
            border-bottom: 3px solid variables.$primary-white;
            display: flex;
            height: clamp(25px, 5vw, 50px);
            width: 100%;
            padding: 24px;
            border-radius: 5px;
          }

          input::placeholder {
            font-size: 1em;

            text-align: start;
            position: absolute;
          }
          svg {
            position: relative;
            left: 100%;
            display: none;
            color: variables.$primary_white;
            cursor: pointer;
          }
          .input_button {
            padding: min(2em, 0.6em) 2em;
            background: variables.$primary_white;
            color: variables.$primary_black;
            height: 100%;
            font-weight: variables.$font_weight_semi_bold;
            border: none;
            border-radius: 5px;
            border: 1px solid variables.$primary_black;
          }
        }
      }
    }
  }

  .footer_navigation {
    display: flex;
    padding: 24px 54px;
    gap: 10px;
    align-self: flex-start;

    .footer_nav_section {
      display: flex;
      align-self: center;

      flex-direction: column;
      flex: 1 0 0;
      padding: 0 0;

      .section_header {
        display: flex;
        padding: 0px 0;
        align-items: center;
        border-bottom: 1px solid variables.$primary_white;
        color: variables.$primary_white;
      }

      .section_list {
        display: flex;
        gap: 4px;
        flex-direction: column;
        align-self: stretch;
        justify-content: center;
      }
    }

    .footer_nav_section:nth-of-type(1) {
      display: flex;

      .section_header {
        display: flex;
        align-items: flex-start;
        padding: 8px 0;
        border-bottom: 1px solid variables.$primary_white;
      }

      .section_list {
        display: flex;
        gap: 4px;
        flex-direction: row;
        align-self: flex-start;
        justify-content: center;

        li {
          color: variables.$primary_white !important;

          a {
            color: variables.$primary_white !important;
          }
        }
      }
    }
  }

  .footer_navigation:nth-of-type(2) {
    .footer_nav_section:nth-of-type() {
      flex-direction: row-reverse;
      width: 100%;
    }
  }

  .footer_navigation:nth-of-type(2) {
    display: flex;
    padding: 24px 54px;
    align-items: flex-start;
    gap: 10px;

    .footer_nav_section:nth-of-type(2) {
      display: flex;
      align-self: flex-start;
      gap: 8px;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      flex: 1 0 0;
      // padding: 24px 24px;

      .section_header:nth-of-type(2) {
        display: flex;
        align-items: flex-start;
        padding: 8px 0;
        border-bottom: 1px solid variables.$primary_white;
      }

      .section_list:nth-of-type(01) {
        display: flex;
        gap: 4px;
        flex-direction: row;
        align-self: flex-start;
        justify-content: center;

        li {
          svg {
            height: 24px;
            cursor: pointer;

            g {
              path {
                fill: variables.$primary_white;
              }
            }
          }
          path {
            fill: variables.$primary_white;
          }
        }
      }
    }
  }
}

@media (max-width: 728px) {
  .footer_container {
    .contact_bar {
      .newsletter_footer_signup {
        width: auto;
        flex-direction: column;

        p {
          padding: 0;
        }
      }
    }
    .footer_navigation {
      flex-direction: column;
      .footer_nav_section {
        padding: 2% 5%;
      }
    }
    .footer_navigation:nth-of-type(2) {
      display: flex;
      padding: 24px 54px;
      align-items: flex-start;
      gap: 10px;

      .footer_nav_section:nth-of-type(2) {
        display: flex;
        align-self: center;
        gap: 8px;
        flex-direction: column;
        flex: 1 0 0;
        padding: 24px 24px;

        .section_header:nth-of-type(2) {
          display: flex;
          align-items: center;
          padding: 8px 0;
          border-bottom: 1px solid variables.$primary_gray;
        }

        .section_list:nth-of-type(01) {
          display: flex;
          gap: 4px;
          flex-direction: row;
          align-self: center;
          justify-content: center;

          li {
            svg {
              height: 24px;
              cursor: pointer;

              g {
                path {
                  fill: variables.$primary_white;
                }
              }
            }
            path {
              fill: variables.$primary_white;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 529px) {
  .footer_container {
    .contact_bar {
      flex-direction: column;

      .contact_btn_container {
        justify-content: center;
      }

      .newsletter_footer_signup {
        flex-direction: column;
        max-width: 100%;

        .shipping_address_input {
          max-width: 50%;
          form {
            display: flex;
            flex-direction: column;

            input.form-control {
              width: 100%;
            }
          }
        }
      }
    }

    .footer_navigation {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .footer_nav_section {
        padding: 0;
        align-items: flex-start;

        .section_header {
          text-align: center;
          width: 100%;
          justify-content: center;
        }
      }
      .footer_nav_section:nth-of-type(2) {
        padding: 0 0;
        align-items: flex-start;
      }

      .section_list {
        justify-content: center;
      }
    }
  }
}
