.main {
  background: #fcfaf7;
  background-position: center;
  background-size: cover;
  max-width: 100vw;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  flex-direction: column;
  padding-top: 54px;
}
.main .logo_white {
  margin-top: 150px;
  width: auto;
  position: relative;
  height: auto;
}
.main .searchbar_section {
  display: grid;
  gap: 8px;
  place-items: center;
  flex-direction: column;
}
.main .searchbar_section h2 {
  max-width: 80vw;
  font-size: clamp(24px, 5vw, 54px);
  font-weight: 600;
  letter-spacing: -2px;
  color: #fcfaf7;
  text-align: center;
  padding: 12px 12px;
}
.main .option_divider {
  font-size: clamp(24px, 5vw, 54px);
  font-weight: 600;
  color: #fcfaf7;
  padding: 24px 10px;
  margin: auto;
}
.main .partner_section {
  background-color: #fcfaf7;
  height: 100%;
  max-width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  text-align: center;
  border-radius: 50px 50px 0 0;
}
.main .partner_section h4 {
  font-size: clamp(16px, 5vw, 36px);
  font-weight: 600;
  color: #07130c;
  letter-spacing: -1.5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main .partner_section h4 .logo_black {
  width: clamp(5rem, 60%, 10rem);
  margin: 0 8px;
}
.main .partner_section .become_partner_btn {
  background-color: #51ce8b;
  font-weight: 600;
  letter-spacing: -2px;
  color: #07130c;
  font-size: clamp(16px, 5vw, 36px);
  border: 1px solid #07130c;
  border-bottom: 6px solid #07130c;
  display: flex;
  text-decoration: none;
  padding: 10px 24px;
  border-radius: 10px;
  cursor: pointer;
}
.main .partner_section .signin_option {
  letter-spacing: -1.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  flex-direction: column;
  padding: 4px;
  margin: 12px;
}
.main .partner_section .signin_option p {
  font-size: clamp(14px, 5vw, 24px);
  font-weight: 400;
  height: 100%;
  display: flex;
  align-items: center;
}
.main .partner_section .signin_option .signin_btn {
  color: #07130c;
  font-weight: 600;
  font-size: clamp(14px, 5vw, 24px);
  padding: 0 4px;
  background: none;
  border: none;
  text-decoration: none;
  cursor: pointer;
}/*# sourceMappingURL=partnerLoginPage.module.css.map */