.product_page_container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 100vw;
  width: 100%;
  padding: 2em 25em;
  background-color: #fcfaf7;
  align-items: center;
}
.product_page_container .header_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
.product_page_container .header_container .header_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.product_page_container .header_container .header_content h1 {
  width: 100%;
  border-bottom: 1px solid rgba(7, 19, 12, 0.6);
  font-size: clamp(16px, 5vw, 36px);
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product_page_container .header_container .header_content a {
  display: flex;
  justify-content: center;
}
.product_page_container .header_container .header_content a .logo {
  margin-top: 150px;
  width: auto;
  position: relative;
  height: auto;
}
.product_page_container .header_container .header_content h1 {
  text-align: center;
  font-size: clamp(24px, 5vw, 54px);
}
.product_page_container .header_container .header_content h5 {
  font-weight: 400;
  font-size: clamp(14px, 5vw, 24px);
  text-align: center;
}
.product_page_container .header_container .header_content .banner {
  height: 350px;
  width: 100%;
  background-color: rgba(81, 206, 139, 0.5);
}
.product_page_container .header_container .buttons_container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  height: 100%;
  position: relative;
}
.product_page_container .header_container .buttons_container a {
  border-top: 1px solid #07130c;
  border-right: 2px solid #07130c;
  border-left: 1px solid #07130c;
  border-bottom: 2px solid #07130c;
  border-radius: 20px;
  padding: 0.5em 2em;
  text-align: center;
}
.product_page_container .header_container .buttons_container .under_50,
.product_page_container .header_container .buttons_container .under_100,
.product_page_container .header_container .buttons_container .under_150 {
  border-top: 1px solid #07130c;
  border-right: 2px solid #07130c;
  border-left: 1px solid #07130c;
  border-bottom: 2px solid #07130c;
  border-radius: 20px;
  padding: 0.5em 1em;
  width: -moz-max-content;
  width: max-content;
  text-align: center;
  background-color: rgba(81, 206, 139, 0);
}
.product_page_container .header_container .buttons_container .sort_filter_container {
  display: flex;
  flex-direction: column;
}
.product_page_container .header_container .buttons_container .sort_filter_container .sort_filter_button {
  border: none;
  background: none;
  padding: 0;
}
.product_page_container .header_container .buttons_container .sort_filter_container span.underline {
  width: 100%;
  height: 1px;
  background: #07130c;
}
.product_page_container .product_section_container {
  display: flex;
  gap: 20px;
  justify-content: space-evenly;
  width: 100%;
  flex-wrap: wrap;
  flex: 1 0 0;
  padding: 0em 0em;
}
.product_page_container .product_section_container h1 {
  width: 100%;
  border-bottom: 1px solid rgba(7, 19, 12, 0.6);
  font-size: clamp(16px, 5vw, 36px);
  font-weight: 700;
  height: 4em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product_nav_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}
.product_nav_container .button_container {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
}
.product_nav_container .button_container .back_button,
.product_nav_container .button_container .next_button {
  background-color: #f9e8bf;
  border-top: 1px solid #07130c;
  border-right: 2px solid #07130c;
  border-left: 1px solid #07130c;
  border-bottom: 2px solid #07130c;
}
.product_nav_container .product_nav_info {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 1800px) {
  .product_page_container {
    padding: 2em 8em;
  }
  .product_page_container .product_section_container {
    padding: 0 0em;
  }
}
@media (max-width: 1024px) {
  .product_page_container {
    padding: 2em 4em;
  }
  .product_page_container .product_section_container {
    padding: 0;
  }
}
@media (max-width: 529px) {
  .product_page_container {
    padding: 2em 8px;
  }
  .product_page_container .product_section_container {
    padding: 0;
  }
  .product_page_container .header_container .header_content h1 {
    font-weight: 500;
  }
  .product_page_container .header_container .header_content h5 {
    letter-spacing: -0.5px;
  }
  .product_page_container .filter_container {
    flex-direction: column;
  }
  .product_page_container .filter_container .price_filter_container .under_50,
  .product_page_container .filter_container .price_filter_container .under_100,
  .product_page_container .filter_container .price_filter_container .under_150 {
    text-transform: lowercase;
    font-size: clamp(14px, 5vw, 12px);
  }
}/*# sourceMappingURL=productPage.module.css.map */