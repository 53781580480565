@use "../variables";

.home_page_container {
  display: flex;
  flex-direction: column;

  background-color: variables.$primary_white;
  max-width: 100vw;
  padding: 2em 8em !important;

  .main_section {
    background-position: center;
    background-size: cover;
    max-width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5vh;
    margin: 125px 0 0 0;

    h1 {
      text-align: center;
    }

    .main_content_container {
      width: 60%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      height: 350px;
      background-color: rgb(variables.$primary_green, 0.3);

      .main_image1 {
        background-image: url(../../../images/main_section_image1.jpg);
        background-size: cover;
        background-position: 50% 75%;
        width: 75%;
        box-shadow: 1px 3px 20px rgb(variables.$primary_black, 0.1);
        height: 100%;
      }
      .main_image2 {
        background-image: url(../../../images/main_section_image2.jpg);
        background-size: cover;
        background-position: 50% 15%;
        width: 100%;
        box-shadow: 1px 3px 20px rgb(variables.$primary_black, 0.1);
        height: 100%;
      }

      h1 {
        font-weight: variables.$font_weight_thin;
        font-size: variables.$h2_font_size;
        letter-spacing: -2px;
        text-align: center;
      }
    }

    .searchbar_section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      place-items: center;
      width: 100%;
      max-height: 100%;

      .logo_white {
        width: autu;
        max-height: 300px;
      }
      h2 {
        max-width: 80vw;
        font-size: variables.$h2_font_size;
        font-weight: variables.$font_weight_semi_bold;
        letter-spacing: max(-2px);
        color: variables.$primary_white;
        text-align: center;
        padding: 12px 12px;
      }

      form {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        gap: 10px;

        .search_btn {
          padding: 1em 2em;

          position: relative;
        }
        .shipping_address_input {
          display: flex;
          position: relative;
          flex-direction: row;
          align-items: center;
          min-width: 60vw;
          width: 100%;
          border-top: 1px solid rgb(variables.$primary_black, 0.2);
          border-right: 3px solid rgb(variables.$primary_black, 0.2);
          border-left: 1px solid rgb(variables.$primary_black, 0.2);
          border-bottom: 3px solid rgb(variables.$primary_black, 0.2);

          border-radius: 5px;

          input {
            font-size: variables.$h5_font_size;
            background-color: variables.$primary_white;
            display: flex;
            border: none;
            height: clamp(25px, 5vw, 100px);
            width: 100%;
            border-radius: 5px;
            min-height: 2em;
            cursor: pointer;
          }

          input::placeholder {
            font-size: 100%;
            text-align: center;
            position: absolute;
            font-weight: variables.$font_weight_mediem;
          }

          .search_icon {
            position: relative;
            left: 10px;
            height: 100%;

            opacity: 0.5;
          }

          svg {
            position: absolute;
            right: 10px;
            height: 100%;
          }
          .suggestion_list {
            position: absolute;
            width: 95%;
            height: fit-content;
            padding: 0 20px 12px 20px;
            display: flex;
            flex-direction: column;
            gap: 12px;
            font-size: variables.$p_font_size;
            font-weight: variables.$font_weight_light;
            border-top: 1px solid variables.$primary_black;
            border-right: 3px solid variables.$primary_black;
            border-left: 1px solid variables.$primary_black;
            border-bottom: 3px solid variables.$primary_black;
            line-height: 1.5em;
            top: 100%;
            background-color: variables.$primary_white;
            z-index: 1;
          }
        }
      }
    }
    .shipping_address_input {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      max-width: 100%;

      form {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        input {
          font-size: variables.$p_font_size;
          border-top: 1px solid variables.$primary_black;
          border-right: 3px solid variables.$primary_black;
          border-left: 1px solid variables.$primary_black;
          border-bottom: 3px solid variables.$primary_black;
          display: flex;
          height: clamp(25px, 5vw, 50px);
          width: 500px;
          padding: 10px;
          border-radius: 5px;
        }

        input::placeholder {
          font-size: 1em;

          text-align: start;
          position: absolute;
        }
        svg {
          position: relative;
          right: 25px;
          cursor: pointer;
        }
      }
      p.error_or_success {
        width: 100%;
        background-color: red;
      }
    }
  }

  .hero_container {
    max-width: 100vw;
    padding: 0 24px;

    .category_nav {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      ul:nth-of-type(1) {
        width: 100%;
        padding: 12px 10%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        border-bottom: 1px solid variables.$primary_gray;
      }
      ul.popular_category_nav {
        width: 100%;
        padding: 12px 20%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        li {
          border-top: 1px solid variables.$primary_black;
          border-right: 2px solid variables.$primary_black;
          border-left: 1px solid variables.$primary_black;
          border-bottom: 2px solid variables.$primary_black;
          border-radius: 20px;
          padding: 10px 0;
          width: 125px;
          text-align: center;
        }
      }
    }

    .hero_content {
      display: flex;
      align-items: center;
      align-items: center;
      justify-content: space-between;
      flex-shrink: 0;
      padding: 0px 24px;
      max-width: 100%;

      .hero_header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        h1 {
          line-height: 1.225em;
          font-size: variables.$h1_font_size;
          font-weight: variables.$font_weight_light;
        }

        span:nth-of-type(1) {
          font-weight: variables.$font_weight_semi_bold;
        }
        span:nth-of-type(2) {
          font-weight: variables.$font_weight_semi_bold;
        }
      }

      // .hero_image {
      //   display: flex;
      //   align-items: center;
      //   justify-content: flex-end;
      //   height: 100%;

      //   img {
      //     height: 100%;
      //     max-width: 95%;
      //   }
      // }
    }
  }

  .hero_divider_container {
    display: flex;
    flex-direction: row;
    max-width: 100vw;

    .green_box {
      display: flex;
      justify-content: flex-end;
      padding: 12px;
      background-color: variables.$primary_green;
      width: 25%;
      h1 {
        font-weight: variables.$font_weight_bold;
        font-size: variables.$h2_font_size;
      }
    }

    .yellow_box {
      padding: 12px;
      background-color: variables.$primary_yellow;
      width: 75%;
      h1 {
        font-weight: variables.$font_weight_bold;
        font-size: variables.$h2_font_size;
      }
    }
  }

  .product_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .product_carousel {
      max-width: 75%;
      div {
        ul {
          position: static;
        }
      }
    }
  }

  .selling_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 50vh;
    width: 100%;
    gap: 36px;

    .holiday_section_container {
      display: flex;
      gap: 12px;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: flex-start;
      padding: 20px 20px;
      max-height: 100%;
      max-width: 100vw;
      background-color: rgb(variables.$primary_green, 0.3);

      .image_container {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;

        .holiday_image {
          height: 100%;
          width: 100%;
          padding: 0.5em;
        }
      }

      .content_container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: 100%;
        width: 50%;
        gap: 36px;
        justify-content: center;
        padding: 20px;

        .header {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          padding: 24px;
          h1 {
            line-height: 1em;
            .bold_text_line {
              letter-spacing: -2px;
              font-size: variables.$h1_font_size;
              font-weight: variables.$font_weight_semi_bold;
            }

            .thin_text_line {
              letter-spacing: -5px;
              font-size: variables.$h1_font_size;
              font-weight: variables.$font_weight_light;
            }
          }
        }

        .sub_header_container {
          display: flex;
          flex-direction: column;
          gap: 0.225em;
          justify-content: center;
          width: 50%;

          .sub_header_yellow {
            width: 100%;

            padding: 0 24px;
            h5 {
              letter-spacing: -0.5px;
              line-height: 1em;
              font-size: variables.$h5_font_size;
              font-weight: variables.$font_weight_thin;
            }
          }
          .sub_header_green {
            width: 100%;
            padding: 0 24px;
            h5 {
              letter-spacing: -1.5px;
              font-size: variables.$h4_font_size;
              font-weight: variables.$font_weight_regular;
              text-decoration: underline;
            }
          }
        }
      }
    }
    h1 {
      font-size: variables.$h2_font_size;
      width: 100%;

      span {
        font-weight: variables.$font_weight_semi_bold;
      }
    }

    .best_selling_content {
      display: flex;
      // flex-direction: column;

      justify-content: space-between;

      width: 100%;
      flex-wrap: wrap;
      flex: 1 0 0;
      gap: 10px;
    }
  }

  .everyone_section_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    padding: 54px 20px;
    height: 100%;
    gap: 10px;
    background-color: rgb(variables.$primary_white, 0.2);

    .header_container {
      width: 35%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 4;
      h1 {
        text-align: center;
        font-weight: variables.$font_weight_semi_bold;
        font-size: variables.$h2_font_size;
      }
    }

    .content_container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      max-width: 100%;
      width: 100%;

      .row_container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;

        .row_content {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 16px;
          flex-wrap: wrap;
          width: 100%;

          button {
            display: flex;
            flex-direction: column;
            padding: 10px;
            background: rgb(variables.$primary_green, 0.1);
            box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
              rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

            border: none;

            .everyone_button_img {
              background: url(../../../images/everyoneSection/for_her_img.jpg)
                lightgray 50% / cover no-repeat;
              background-position: 0% 50%;
              width: 250px;
              height: 250px;
            }
          }

          .for_everyone_button {
            padding: 1em 3em;
          }
        }
      }
      //changing images in the everyone section
      .row_container:nth-of-type(1) {
        .row_content {
          button:nth-of-type(2) {
            .everyone_button_img {
              background: url(../../../images/everyoneSection/for_him_img.jpg)
                lightgray 50% / cover no-repeat;
            }
          }
        }
      }
      .row_container:nth-of-type(1) {
        .row_content {
          button:nth-of-type(3) {
            .everyone_button_img {
              background: url(../../../images/everyoneSection/for_kids_img.jpg)
                lightgray 50% / cover no-repeat;
            }
          }
        }
      }
      .row_container:nth-of-type(1) {
        .row_content {
          button:nth-of-type(4) {
            .everyone_button_img {
              background: url(../../../images/everyoneSection/for_teens_img.jpg)
                lightgray 50% / cover no-repeat;
            }
          }
        }
      }
      .row_container:nth-of-type(2) {
        .row_content {
          button:nth-of-type(1) {
            .everyone_button_img {
              background: url(../../../images/everyoneSection/for_dads_img.jpg)
                lightgray 50% / cover no-repeat;
            }
          }
        }
      }
      .row_container:nth-of-type(2) {
        .row_content {
          button:nth-of-type(2) {
            .everyone_button_img {
              background: url(../../../images/everyoneSection/for_moms_img.jpg)
                lightgray 50% / cover no-repeat;
            }
          }
        }
      }
      .row_container:nth-of-type(2) {
        .row_content {
          button:nth-of-type(3) {
            .everyone_button_img {
              background: url(../../../images/everyoneSection/for_business.jpg)
                lightgray 50% / cover no-repeat;
            }
          }
        }
      }
      .row_container:nth-of-type(2) {
        .row_content {
          button:nth-of-type(4) {
            .everyone_button_img {
              background: url(../../../images/everyoneSection/for_everyone_img.png)
                lightgray 50% / cover no-repeat;
            }
          }
        }
      }
    }
  }

  .tech_section_container {
    display: flex;
    flex-direction: row;
    max-width: 100vw;
    height: 100%;

    justify-content: space-between;
    padding: 54px 24px;
    background-color: rgb(variables.$primary_white, 0.3);

    .content_row_container:nth-of-type(1) {
      width: 100%;

      .gift_with_love_img {
        height: 400px;
        width: 95%;
        background: url("../../../images/tech-section/love_section_img.jpg")
          lightgray 50% / cover no-repeat;
      }
    }
    .content_row_container {
      width: 50%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      gap: 0.8em;
      flex-shrink: 1;
      gap: 24px;

      h3 {
        font-size: variables.$h1_font_size;
        font-weight: variables.$font_weight_light;
        letter-spacing: -1.5px;
        padding: 0 24px;
        line-height: 1em;
        text-align: center;
        padding: 0.225em 0;

        span.span_bold {
          font-weight: variables.$font_weight_semi_bold;
        }
      }

      .sub_header_container {
        .sub_header_yellow {
          width: 100%;

          button {
            width: 100%;
            height: 100%;
            background: none;
            border: none;
            display: flex;
            align-items: flex-start;
            h3 {
              font-size: variables.$h5_font_size;
              font-weight: variables.$font_weight_light;
            }
          }
        }
        .sub_header_green {
          width: 100%;
          button {
            width: 100%;
            height: 100%;
            background: none;
            border: none;
            display: flex;
            align-items: flex-start;

            h3 {
              font-size: variables.$h4_font_size;
              font-weight: variables.$font_weight_mediem;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .gift_type_section_container {
    max-width: 100vw;
    width: 100%;
    padding: 54px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .header_container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1em 0;
      width: fit-content;

      .header {
        font-size: variables.$h2_font_size;
        .bold_text {
          font-weight: variables.$font_weight_semi_bold;
        }
      }
    }

    .content_container {
      display: flex;
      flex-direction: row;
      gap: 24px;
      width: 100%;

      .content_column_container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0 24px;

        .list_header_container {
          width: 100%;
          padding: 4px 8px;
          text-align: center;

          border-top: 1px solid variables.$primary_black;
          border-right: 3px solid variables.$primary_black;
          border-left: 1px solid variables.$primary_black;
          border-bottom: 3px solid variables.$primary_black;
          background-color: rgb(variables.$background_brown, 0.6);
          h5 {
            font-weight: variables.$font_weight_regular;
          }
        }

        ul {
          padding: 8px 24px;
        }
      }
    }
  }
}

@media (min-width: 1900px) {
  .home_page_container {
    padding: 2em 25em !important;
  }
}

@media (max-width: 1024px) {
  .home_page_container {
    padding: 2em 2em !important;
    .main_section {
      .main_content_container {
        height: 300px;
        .main_image2 {
          display: none;
        }

        .main_image1 {
          width: 100%;
        }
      }
    }

    .everyone_section_container {
      flex-direction: column;
      gap: 1em;

      .header_container {
        h1 {
          text-align: center;
        }
      }

      .content_container {
        .row_container {
          .row_content {
            button {
              .everyone_button_img {
                background: url(../../../images/everyoneSection/for_her_img.jpg)
                  lightgray 50% / cover no-repeat;
                background-position: 0% 50%;
                width: 40vw;
                height: 300px;
              }
            }
          }
        }
      }
    }
    .gift_type_section_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1em;
      .header_container {
        h3 {
          font-size: variables.$h1-font-size;
          text-align: center;
        }
      }
    }

    .selling_container {
      .holiday_section_container {
        flex-direction: column-reverse;
        .content_container {
          width: 100%;
          .header {
            justify-content: center;
            width: 100%;
            h1 {
              text-align: center;
              width: 100%;
              span.thin_text_line {
                letter-spacing: -2px;
              }
              br {
                display: none;
              }
            }
          }

          .sub_header_container {
            width: 100%;
            div {
              h5 {
                text-align: center;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 728px) {
  br {
    display: none;
  }
  .home_page_container {
    padding: 2em 2em !important;
    .main_section {
      .searchbar_section {
        form {
          width: 80%;
          flex-direction: column;
        }
      }

      .main_content_container {
        .main_image1 {
          height: 250px;
        }
      }
    }

    .gift_type_section_container {
      .content_container {
        padding: 0;
        .content_column_container {
          padding: 0;
        }
      }
    }
    .tech_section_container {
      flex-direction: column-reverse;
      padding: 0;
      align-items: center;
      gap: 10px;
      .content_row_container {
        width: 100%;
        align-items: center;
        .sub_header_container {
          .sub_header_yellow {
            button {
              justify-content: center;
              align-items: center;
              h3 {
                text-align: center;
              }
            }
          }
          .sub_header_green {
            button {
              justify-content: center;
              align-items: center;
              h3 {
                text-align: center;
              }
            }
          }
        }
      }
    }
    .everyone_section_container {
      .content_container {
        .row_container {
          .row_content {
            flex-direction: row;
            button {
              width: 40vw;
              .everyone_button_img {
                background: url(../../../images/everyoneSection/for_her_img.jpg)
                  lightgray 50% / cover no-repeat;
                background-position: 0% 50%;
                width: 100%;
                max-height: 250px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 529px) {
  .home_page_container {
    padding: 0 8px !important;

    .main_section {
      margin: 15vh 0 0 0;
      h1 {
        letter-spacing: -0.5px;
      }
      h4 {
        text-align: center;
      }

      .main_content_container {
        height: 200px;
        display: none;
        .main_image1 {
          height: 100%;
        }
      }

      .searchbar_section {
        width: 100%;

        .logo_white {
          width: max(70vw, 70vw);
        }
        form {
          width: 100%;

          .shipping_address_input {
            width: 100%;

            input {
              height: 3em;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: variables.$p_font_size;
            }
          }
        }
      }
    }

    .hero_container {
      padding: 0;
      .hero_content {
        flex-direction: column;
        padding: 0;
        .hero_header {
          h1 {
            text-align: center;
          }
        }
        .hero_image {
          img {
            max-width: 100%;
          }
        }
      }
    }

    .selling_container {
      padding: 0;
      .holiday_section_container {
        padding: 0;
        flex-direction: column-reverse;
        gap: 10px;

        .content_container {
          width: 100%;
          .header {
            padding: 0;
            h1 {
              text-align: center;
              line-height: 1em;
              span.bold_text_line {
                letter-spacing: 0px;
              }
              span.thin_text_line {
                letter-spacing: -0.5px;
              }
            }
          }
          .sub_header_yellow {
            h5 {
              letter-spacing: -0.5px;
            }
          }
          .sub_header_green {
            h5 {
              letter-spacing: -0.5px;
            }
          }
        }
      }

      .best_selling_content {
        justify-content: center;
      }
    }

    .everyone_section_container {
      padding: 0;
      max-width: 100%;

      .header_container {
        width: 100%;
      }
      .content_container {
        .row_container {
          width: 100%;
          display: block;
          .row_content {
            flex-direction: column;
            button {
              width: 100%;
            }
          }
        }
      }
    }

    .tech_section_container {
      flex-direction: column-reverse;
      padding: 0;
      align-items: center;
      .content_row_container {
        width: 100%;
        align-items: center;
      }
    }

    .gift_type_section_container {
      .content_container {
        padding: 0;
        flex-direction: column;

        .content_column_container {
          padding: 0;
          align-items: center;
          text-align: center;

          li {
            padding: 4px 0;
          }
        }
      }
    }
  }
}
