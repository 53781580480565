@use "../variables";

.about_page {
  max-width: 100vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: variables.$primary-white;
  padding: 2em 0;

  a {
    svg {
      margin-top: 150px;
      width: auto;
      position: relative;
      height: auto;
    }
  }

  .about_container {
    width: 100%;

    .about_content {
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 2em;
      padding: 2em 10em;

      h1 {
        width: 100%;
        border-bottom: 1px solid rgba(variables.$primary_black, 0.6);
        font-size: variables.$h4_font_size;
        height: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      p {
        color: rgba(variables.$primary_black, 0.6);
      }
    }
  }
}

@media (min-width: 1900px) {
  .about_page {
    .about_container {
      .about_content {
        padding: 2em 40em !important;
      }
    }
  }
}

@media (max-width: 1024px) {
  .about_page {
    .about_container {
      .about_content {
        padding: 2em 2em;

        p {
          color: rgba(variables.$primary_black, 0.6);
        }
      }
    }
  }
}
