.landing_page_container {
  display: flex;
  background-color: #fcfaf7;
  max-width: 100vw;
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 5vh 0;
  gap: 1em;
}
.landing_page_container .logo_white {
  margin-top: 150px;
  width: auto;
  position: relative;
  height: auto;
}
.landing_page_container .landing_header {
  text-align: center;
  margin-bottom: 24px;
}
.landing_page_container .input_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #51ce8b;
  padding: 20px;
  gap: 10px;
}
.landing_page_container .input_container .text_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 80vw;
  height: 100%;
}
.landing_page_container .input_container .text_container h5,
.landing_page_container .input_container .text_container h1 {
  width: 50vw;
  font-size: clamp(16px, 5vw, 36px);
  font-weight: 600;
  text-align: start;
  padding: 0 12px 12px 0px;
  display: flex;
  justify-items: center;
  align-items: center;
}
.landing_page_container .input_container .image_container {
  width: 100%;
}
.landing_page_container .input_container .image_container .banner_image {
  background: url("../../../images/landingPage/landing-page.JPEG");
  background-position: center center;
  height: min(300px, 50vw);
  background-size: cover;
}
.landing_page_container .input_container .shipping_address_input {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: min(90vw, 500px);
}
.landing_page_container .input_container .shipping_address_input form {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex-direction: column;
}
.landing_page_container .input_container .shipping_address_input form input {
  font-size: clamp(14px, 5vw, 16px);
  border-top: 1px solid #07130c;
  border-right: 3px solid #07130c;
  border-left: 1px solid #07130c;
  border-bottom: 3px solid #07130c;
  display: flex;
  height: clamp(25px, 5vw, 50px);
  width: min(400px, 30vw);
  padding: 10px;
  border-radius: 5px;
}
.landing_page_container .input_container .shipping_address_input form input::-moz-placeholder {
  font-size: 1em;
  text-align: start;
  position: absolute;
}
.landing_page_container .input_container .shipping_address_input form input::placeholder {
  font-size: 1em;
  text-align: start;
  position: absolute;
}
.landing_page_container .input_container .shipping_address_input form svg {
  position: relative;
  right: 25px;
  cursor: pointer;
}
.landing_page_container .input_container .shipping_address_input form .input_button {
  padding: 0.6em 2em;
  background: #07130c;
  color: #fcfaf7;
  height: 100%;
  font-weight: 600;
  border: none;
  border-radius: 5px;
}
.landing_page_container .input_container .shipping_address_input p.error_or_success {
  width: 100%;
  background-color: red;
}
.landing_page_container .product_container {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 20px);
  gap: 1em;
}
.landing_page_container h1 {
  font-weight: 600;
  text-align: center;
  font-size: clamp(36px, 5vw, 81px);
  padding: 24px 20px 0 20px;
}

@media (min-width: 1800px) {
  .landing_page_container .input_container {
    height: 100%;
  }
  .landing_page_container .input_container .text_container {
    width: 100%;
    justify-content: center;
  }
  .landing_page_container .input_container .text_container h5 {
    max-width: 100%;
  }
  .landing_page_container .input_container .image_container {
    width: 50%;
  }
  .landing_page_container .input_container .image_container .banner_image {
    height: 350px;
  }
  .landing_page_container .product_container {
    display: flex;
    flex-wrap: wrap;
    width: 75%;
    justify-content: center;
  }
  .landing_page_container .product_container h1 {
    font-weight: 600;
    font-size: clamp(36px, 5vw, 81px);
    padding: 24px 20px 0 20px;
  }
}
@media (max-width: 1024px) {
  .landing_page_container .input_container {
    flex-direction: column;
  }
  .landing_page_container .input_container .text_container h5,
  .landing_page_container .input_container .text_container h1 {
    width: 100%;
  }
  .landing_page_container .input_container .shipping_address_input form input {
    min-width: 80vw;
  }
  .landing_page_container .input_container .banner_image {
    display: none;
  }
}
@media (max-width: 724px) {
  .landing_page_container .input_container {
    padding: 2em 20px;
    flex-direction: column;
  }
  .landing_page_container .input_container .text_container .shipping_address_input form {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}/*# sourceMappingURL=landingPage.module.css.map */