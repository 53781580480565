.checkout_form {
  min-height: 100vh;
  margin-top: 80px;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 2em;
  background-color: #fcfaf7;
  min-height: 75vh;
}
.checkout_form a {
  width: 100%;
  display: flex;
  justify-content: center;
}
.checkout_form a svg {
  margin-top: 150px;
  width: auto;
  position: relative;
  height: auto;
}
.checkout_form .banner_container {
  padding: 0 15em;
}
.checkout_form .banner_container:nth-of-type(3) {
  margin-bottom: 3em !important;
}

@media (min-width: 1900px) {
  .checkout_form .banner_container {
    padding: 0 30em;
  }
}
@media (max-width: 728px) {
  .checkout_form .banner_container {
    padding: 0 10px;
  }
  .checkout_form .banner_container:nth-of-type(3) {
    margin-bottom: 2em !important;
  }
}/*# sourceMappingURL=checkoutForm.module.css.map */