.calander_container {
  max-width: 100vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fcfaf7;
  padding: 2em 10em;
  text-align: center;
  gap: 8px;
}
.calander_container a svg {
  margin-top: 150px;
  width: auto;
  position: relative;
  height: auto;
}
.calander_container bl {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: auto;
  flex-direction: column;
  gap: 8px;
}
.calander_container bl li {
  text-align: left;
}

@media (min-width: 1900px) {
  .calander_container {
    padding: 2em 40em !important;
  }
}
@media (max-width: 1024px) {
  .calander_container {
    padding: 2em 2em;
  }
}/*# sourceMappingURL=calander.module.css.map */