@use "../variables";

.banner {
  height: 350px;
  width: 100%;
  //   background-color: rgba(variables.$primary_green, 0.5);
  box-sizing: content-box;
}

.birthday_banner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 269px;
  gap: 20px;
  margin-bottom: 2em;
  background-color: none;

  .section_1 {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    border-top: 1px solid rgba(7, 19, 12, 0.3);
    border-right: 3px solid rgba(7, 19, 12, 0.3);
    border-bottom: 3px solid rgba(7, 19, 12, 0.3);
    border-left: 1px solid rgba(7, 19, 12, 0.3);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;

    .section_1_img {
      background-image: url("../../../images/banner/birthdaycake.jpg");
      width: 30%;
      height: 269px;
      background-size: cover;
      background-position: center;
      z-index: 1;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
    .section_1_content {
      display: flex;
      flex-direction: row;
      height: 100%;
      width: 60%;
      justify-content: center;
      align-items: center;
      gap: 15px;
      background-color: variables.$primary_white;
      .text_container {
        p {
          color: variables.$primary_black;
        }
      }
      .banner_link {
        width: 20%;
        background: #ec85b4;
        padding: 4px 8px;
        border-radius: 10px;
        color: variables.$primary_white;
        font-weight: variables.$font_weight_semi_bold;
      }
    }
  }
}
.occasion_banner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 235px;
  gap: 20px;
  margin-bottom: 2em;
  background-color: none;

  .section_1 {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    border-top: 1px solid rgba(7, 19, 12, 0.3);
    border-right: 3px solid rgba(7, 19, 12, 0.3);
    border-bottom: 3px solid rgba(7, 19, 12, 0.3);
    border-left: 1px solid rgba(7, 19, 12, 0.3);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;

    .section_1_img {
      background-image: url("../../../images/banner/halloween-occasion.jpg");
      width: 60%;
      height: 100%;
      background-size: cover;
      background-position: center;
      z-index: 1;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
    .section_1_content {
      display: flex;
      flex-direction: row;
      height: 100%;
      width: 40%;
      justify-content: center;
      align-items: center;
      gap: 15px;
      background-color: variables.$primary_white;
      .text_container {
        p {
          color: #fc8101;
          font-weight: variables.$font_weight_semi_bold;
        }
      }
      .banner_link {
        width: 5em;
        background: #fc8101;
        padding: 4px 8px;
        border-radius: 10px;
        color: variables.$primary_white;
      }
    }
  }
}
.explore_banner {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: auto;
  gap: 20px;
  margin-bottom: 2em;
  background-color: none;
  .header_container {
    width: 100%;
    height: auto;
    .header {
      font-size: variables.$h2_font_size;
      text-align: center;
    }
  }

  .explore_list {
    display: flex;
    flex-direction: row;
    height: 25em;
    width: 100%;
    background-image: url("../../../images/banner/explore-gifts.jpg");
    background-position: 50% 50%;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;

    .list_item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25%;
      height: 100%;

      a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        .item_img {
          width: 75%;
          height: 100%;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          cursor: pointer;
          filter: drop-shadow(3px 5px 14px rgba(variables.$primary_black, 0.5));
        }
      }

      .season {
        background-image: url("../../../images/banner/halloween-btn.png");
      }
      .wedding {
        background-image: url("../../../images/banner/wedding-btn.png");
      }
      .birthday {
        background-image: url("../../../images/banner/birthday-btn.png");
      }
      .gourmet {
        background-image: url("../../../images/banner/gourmet-btn.png");
      }
    }
  }
}

.taste_canada_banner {
  width: 100%;
  height: 269px;
  display: flex;
  flex-direction: row;

  .img_container {
    display: flex;
    height: 269px;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;

    .left_branch_img {
      background-image: url("../../../images/banner/left-maple-branch.png");
      height: 100%;
      background-position: top;
      background-repeat: no-repeat;
      width: 100%;
      background-size: contain;
    }
  }
  .left_leaf_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 5%;
    height: 100%;

    .left_top_leaf_img {
      background-image: url("../../../images/banner/left-top-leaf.png");
      height: 100%;
      background-repeat: no-repeat;
      width: 100%;
      background-size: contain;
      background-position: 0 10%;
    }
    .left_bottom_leaf_img {
      background-image: url("../../../images/banner/left-bottom-leaf.png");
      height: 100%;
      background-repeat: no-repeat;
      width: 100%;
      background-size: contain;
      background-position: 0 90%;
    }
  }
  .right_leaf_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 5%;
    height: 100%;

    .right_top_leaf_img {
      background-image: url("../../../images/banner/right-top-leaf.png");
      height: 100%;
      background-repeat: no-repeat;
      width: 100%;
      background-size: contain;
      background-position: 0 10%;
    }
    .right_bottom_leaf_img {
      background-image: url("../../../images/banner/right-bottom-leaf.png");
      height: 100%;
      background-repeat: no-repeat;
      width: 100%;
      background-size: contain;
      background-position: 0 90%;
    }
  }
  .img_container {
    display: flex;
    height: 350px;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    .right_branch_img {
      background-image: url("../../../images/banner/right-maple-branch.png");
      height: 100%;
      background-position: top;
      background-repeat: no-repeat;
      width: 100%;
      background-size: contain;
    }
  }
  .content_container {
    height: 100%;
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1 {
      text-align: center;

      span {
        color: variables.$primary_red;
      }
    }

    a {
      background: variables.$primary_red;
      padding: 4px 8px;
      border-radius: 10px;
      color: variables.$primary_white;
    }
  }
}

.category_banner {
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  .img_container {
    background-image: url("../../../images/banner/category-banner.png");
    width: 100%;
    height: 0;
    padding-top: 37.65%; // adjust this value to set the aspect ratio of the image
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  h2 {
    position: absolute;
    font-size: variables.$h2_font_size;
    font-weight: variables.$font_weight_semi_bold;
    z-index: 1;
    margin-right: 4em;
    left: 60%;
  }
}
.for_her_banner1 {
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  .img_container {
    // background-image: url("../../../images/banner/forHerBanner.png");
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}
.for_her_banner2 {
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  .img_container {
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}
.ready_for_shopping_banner {
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  .img_container {
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}
.for_kids_banner {
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  .img_container {
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}
.all_banner {
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  .img_container {
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}

@media (max-width: 728px) {
  .birthday_banner {
    height: 269px;
    .section_1 {
      flex-direction: column;
      .section_1_img {
        width: 100%;
        height: 400px;
        background-position: 0% 45%;
      }
      .section_1_content {
        width: 100%;
        height: 150px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;
        .text_container {
          p {
            font-size: variables.$p_sm_font_size;
          }
        }
      }
    }
  }
  .occasion_banner {
    height: 235px;
    .section_1 {
      flex-direction: column;
      .section_1_img {
        width: 100%;
        height: 235px;
        background-position: 0% 45%;
      }
      .section_1_content {
        width: 100%;
        height: 235px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
        .text_container {
          p {
            font-size: variables.$p_sm_font_size;
          }
        }
      }
    }
  }

  .explore_banner {
    .explore_list {
      height: 10em;
      .list_item {
        padding: 0 0.5em;
        a {
          .item_img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .taste_canada_banner {
    height: 169px;
    .img_container {
      height: 169px;
    }
  }
}

@media (max-width: 728px) {
  .explore_banner {
    .explore_list {
      height: 15em;
      .list_item {
        padding: 0 0.5em;
        a {
          .item_img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
