@use "../variables";

.terms_conditions_page {
  max-width: 100vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: variables.$primary-white;
  padding: 2em 0;

  a {
    svg {
      margin-top: 150px;
      width: auto;
      position: relative;
      height: auto;
    }
  }

  .terms_conditions_container {
    width: 100%;

    .content {
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 4px;
      padding: 2em 10em !important;

      h1 {
        width: 100%;
        border-bottom: 1px solid rgba(variables.$primary_black, 0.6);
        font-size: variables.$h4_font_size;
        height: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      p {
        color: rgba(variables.$primary_black, 0.6);
      }
    }
  }
}
@media (min-width: 1900px) {
  .terms_conditions_page {
    .terms_conditions_container {
      .content {
        padding: 2em 40em !important;
      }
    }
  }
}
@media (max-width: 1024px) {
  .terms_conditions_page {
    .terms_conditions_container {
      .content {
        padding: 2em 4em !important;
        p {
          color: rgba(variables.$primary_black, 0.6);
        }
      }
    }
  }
}
@media (max-width: 728px) {
  .terms_conditions_page {
    a {
      svg {
        width: 100%;
      }
    }
    .privacy_policy_container {
      .content {
        padding: 2em 8px !important;
        p {
          color: rgba(variables.$primary_black, 0.6);
        }
      }
    }
  }
}
