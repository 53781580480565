.admin_section {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2em;
  gap: 1em;
}
.admin_section .content_container .shipping_methods_admin {
  width: 100%;
}/*# sourceMappingURL=admin.module.css.map */