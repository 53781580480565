@use "../variables";

.item_page_container {
  background-color: variables.$primary_white;
  max-width: 100vw;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 2em 8em;

  .logo {
    margin-top: 150px;
    width: auto;
    position: relative;
    height: auto;
  }
  .item_content_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: min(100%, 80vh);

    .item_image_container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60%;
      padding: 36px 0;
      height: max(80vh, 800px);
      position: relative;

      span.background_color {
        display: flex;
        align-items: center;
        justify-content: center;
        // box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
        //   rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        // background: rgba(variables.$primary_green, 0.2);
        width: 100%;
        position: relative;

        .item_image {
          height: 100%;
          max-width: 100%;
          width: 100%;
          background-position: center center;
          background-size: contain;
          background-repeat: no-repeat;
          box-shadow: rgba(variables.$primary_black, 0.1) 0px 24px 24px 0px;
        }
      }
    }

    .item_information_container {
      width: 50%;
      padding: 4em;
      gap: 1em;
      display: flex;
      flex-direction: column;
      justify-content: stretch;

      .item_product_id {
        color: variables.$primary_gray;
      }

      .item_product_name {
        font-size: variables.$h2_font_size;
        font-weight: variables.$font_weight_mediem;
        letter-spacing: -1px;
        line-height: 1.225em;
        padding: 0 0 0.225em 0;
        border-bottom: solid 1px rgba(variables.$primary_gray, 0.5);
      }

      .discription_container {
        display: flex;
        flex-direction: column;
        height: auto;
        padding: 1em 0 1em 0;
        gap: 0.5em;

        h5 {
          font-weight: variables.$font_weight_regular;
        }

        .item_discription {
          width: 100%;
          position: relative;
          font-size: variables.$p_sm_font_size;
          line-height: 1.5em;
        }
      }

      .price_container {
        padding: 1em 0 1.5em 0;

        .item_price {
          font-weight: variables.$font_weight_mediem;
          font-size: variables.$h5_font_size;
        }

        span.rating_container {
          .star_icon {
            margin-bottom: 1em;
            cursor: pointer;
          }
        }

        .add_cart_container {
          width: 100%;
          display: flex;
          gap: 0.5em;

          .qty_container {
            display: flex;
            height: 1.5em;
            gap: 0.5em;
          }

          span {
            display: flex;
            gap: 4px;

            label {
              font-size: variables.$p_font_size;
              color: rgba(variables.$primary_gray, 0.5);
              font-weight: variables.$font_weight_light;
            }
          }

          button {
            width: 100%;
            border: none;
            background: variables.$primary_red;
            color: variables.$primary_white;
          }
        }
      }
      .image_carousel_container {
        padding: 2em 0 0 0;
        height: 100%;

        div {
          height: 100%;

          div {
            height: auto;
            display: flex;
            .item_image {
              height: auto;
              width: 200px;
            }
          }
        }
      }
    }
  }
  .any_gift_section {
    padding: 2em 0em 2em 0em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 4em;

    h4 {
      font-size: variables.$h4_font_size;
      text-align: center;
      padding: 0 0 1em 0;
      width: 100%;
      border-bottom: solid 1px rgba(variables.$primary_gray, 0.5);
    }
    div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        div {
          max-height: 300px;
          img {
            height: 100%;
            width: 80% !important;
            position: relative;
          }
        }
      }
    }
  }

  .review_section {
    padding: 2em 0em 0 0em;
    width: 100%;
    border-bottom: solid 1px rgba(variables.$primary_gray, 0.5);

    h3 {
      text-align: center;
      font-size: variables.$h2_font_size;
      border-bottom: solid 1px rgba(variables.$primary_gray, 0.5);
    }

    .review_form {
      display: flex;
      flex-direction: column;
    }

    .review_button {
      border: none;
      background: variables.$primary_black;
      color: variables.$primary_white;
      font-size: variables.$p_font_size;
      padding: 0.225em 1em;
    }

    .review_container {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 300px;
      width: 100%;
      flex-direction: column;
      gap: 2em;

      .post_content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        gap: 4px;
      }
    }
  }
}

@media (min-width: 1800px) {
  .item_page_container {
    padding: 2em 25em !important;
  }
}

@media (max-width: 1024px) {
  .item_page_container {
    .item_content_container {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .item_image_container {
        width: 100%;
        max-height: 800px;
      }

      .item_information_container {
        width: 100%;
        padding: 2em 2em 2em 2em;

        .discription_container {
          padding: 0;
          gap: 0.5em;
          h5 {
            padding: 8px;
          }
        }
      }
    }
  }
}

@media (max-width: 748px) {
  .item_page_container {
    padding: 2em 8px;
    .item_content_container {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .item_image_container {
        width: 100%;
        height: auto;
      }

      .item_information_container {
        width: 100%;
        padding: 2em 2em 2em 2em;

        .discription_container {
          padding: 0;
          gap: 0.5em;
          h5 {
            padding: 8px;
          }
        }
        .any_gift_section {
          padding: 4em 0 2em 0;
          border-bottom: solid 1px rgba(variables.$primary_gray, 0.5);
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;

          h3 {
            font-size: variables.$h4_font_size;
            text-align: center;
            padding: 0 0 1em 0;
          }

          div {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            div {
              height: 100%;
              img {
                height: 100%;
                width: 100% !important;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 564px) {
  .item_page_container {
    .logo {
      width: 100%;
      position: relative;
    }

    .item_content_container {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .item_image_container {
        width: 100%;
        height: auto;
        padding: 0;
      }

      .item_information_container {
        width: 100%;
        padding: 2em 8px;

        .discription_container {
          padding: 0;
          gap: 0.5em;
          h5 {
            padding: 8px;
          }
        }
      }
    }
  }
}
