// color variables
$primary_white: #fcfaf7;
$primary_green: #51ce8b;
$primary_red: #f04141;
$primary_black: #07130c;
$primary_blue: #0731c8;
$primary_yellow: #f9d068;
$background_brown: #f9e8bf;
$primary_gray: rgba(7, 19, 12, 0.3);
$error_red: #f67373;

//all typography styles variables
// typography size variables
$h1_font_size: clamp(36px, 5vw, 81px);
$h2_font_size: clamp(24px, 5vw, 54px);
$h4_font_size: clamp(16px, 5vw, 36px);
$h5_font_size: clamp(14px, 5vw, 24px);
$p_font_size: clamp(14px, 5vw, 16px);
$p_sm_font_size: clamp(14px, 5vw, 12px);

// typography weight variables
$font_weight_bold: 700;
$font_weight_semi_bold: 600;
$font_weight_mediem: 500;
$font_weight_regular: 400;
$font_weight_light: 300;
$font_weight_thin: 200;

//typography letter spacing
$h2_letter_spacing: -2px;
$h5_letter_spacing: -1.5px;
$p_letter_spacing: -0.5px;
