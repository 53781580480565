@use "../variables";

.product_page_container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 100vw;
  width: 100%;
  padding: 2em 25em;
  background-color: variables.$primary-white;
  align-items: center;

  .header_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    .header_content {
      width: calc(100%);
      display: flex;
      flex-direction: column;
      gap: 1em;

      h1 {
        width: 100%;
        border-bottom: 1px solid rgba(variables.$primary_black, 0.6);
        font-size: variables.$h4_font_size;
        height: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      a {
        display: flex;

        justify-content: center;
        .logo {
          margin-top: 150px;
          width: auto;
          position: relative;
          height: auto;
        }
      }

      h1 {
        text-align: center;
        font-size: variables.$h2_font_size;
      }
      h5 {
        font-weight: variables.$font_weight_regular;
        font-size: variables.$h5_font_size;
        text-align: center;
      }

      .banner {
        height: 350px;
        width: 100%;
        background-color: rgba(variables.$primary_green, 0.5);
      }
    }

    .buttons_container {
      width: 100%;
      display: flex;
      justify-content: space-around;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
      height: 100%;
      position: relative;

      a {
        border-top: 1px solid variables.$primary_black;
        border-right: 2px solid variables.$primary_black;
        border-left: 1px solid variables.$primary_black;
        border-bottom: 2px solid variables.$primary_black;
        border-radius: 20px;
        padding: 0.5em 2em;

        text-align: center;
      }
      .under_50,
      .under_100,
      .under_150 {
        border-top: 1px solid variables.$primary_black;
        border-right: 2px solid variables.$primary_black;
        border-left: 1px solid variables.$primary_black;
        border-bottom: 2px solid variables.$primary_black;
        border-radius: 20px;
        padding: 0.5em 1em;
        width: max-content;
        text-align: center;
        background-color: rgba(variables.$primary_green, 0);
      }
      .sort_filter_container {
        display: flex;
        flex-direction: column;
        .sort_filter_button {
          border: none;
          background: none;
          padding: 0;
        }
        span.underline {
          width: 100%;
          height: 1px;
          background: variables.$primary_black;
        }
      }
    }
  }

  .product_section_container {
    display: flex;
    gap: 20px;
    justify-content: space-evenly;
    width: 100%;
    flex-wrap: wrap;
    flex: 1 0 0;
    padding: 0em 0em;

    h1 {
      width: 100%;
      border-bottom: 1px solid rgba(variables.$primary_black, 0.6);
      font-size: variables.$h4_font_size;
      font-weight: variables.$font_weight_bold;

      height: 4em;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.product_nav_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;

  .button_container {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;

    .back_button,
    .next_button {
      background-color: variables.$background_brown;
      border-top: 1px solid variables.$primary_black;
      border-right: 2px solid variables.$primary_black;
      border-left: 1px solid variables.$primary_black;
      border-bottom: 2px solid variables.$primary_black;
    }
  }

  .product_nav_info {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 1800px) {
  .product_page_container {
    padding: 2em 8em;
    .product_section_container {
      padding: 0 0em;
    }
  }
}

@media (max-width: 1024px) {
  .product_page_container {
    padding: 2em 4em;
    .product_section_container {
      padding: 0;
    }
  }
}

@media (max-width: 529px) {
  .product_page_container {
    padding: 2em 8px;
    .product_section_container {
      padding: 0;
    }
    .header_container {
      .header_content {
        h1 {
          font-weight: variables.$font_weight_mediem;
        }
        h5 {
          letter-spacing: -0.5px;
        }
      }
    }
    .filter_container {
      flex-direction: column;
      .price_filter_container {
        .under_50,
        .under_100,
        .under_150 {
          text-transform: lowercase;
          font-size: variables.$p_sm_font_size;
        }
      }
    }
  }
}
