.cart_page {
  max-width: 100vw;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 2em 8em;
  gap: 2em;
}
.cart_page .cart_logo_container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.cart_page .cart_logo_container .logo {
  margin-top: 150px;
  width: auto;
  position: relative;
  height: auto;
}
.cart_page .cart_header_container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  max-height: 200px;
}
.cart_page .cart_header_container h5 {
  font-weight: 400;
  color: #07130c;
  text-align: left;
  width: 100%;
}
.cart_page .cart_content_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 4em;
  margin: 4em 0;
}
.cart_page .cart_content_container .cart_content {
  display: flex;
  flex-direction: column;
  max-width: 75%;
}
.cart_page .cart_content_container .cart_content .item_content {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 2em;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: rgba(252, 250, 247, 0.1);
  padding: 2em;
  border-bottom: 1px solid rgba(7, 19, 12, 0.2);
}
.cart_page .cart_content_container .cart_content .item_content div {
  width: 20%;
}
.cart_page .cart_content_container .cart_content .item_content div .quantity_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.255em;
  align-items: center;
  justify-content: center;
}
.cart_page .cart_content_container .cart_content .item_content div .quantity_container label {
  font-size: clamp(14px, 5vw, 16px);
  color: rgba(7, 19, 12, 0.5);
  font-weight: 300;
}
.cart_page .cart_content_container .cart_content .item_content div .quantity_container button {
  width: 100%;
  border: none;
  background: #f04141;
  color: #fcfaf7;
}
.cart_page .cart_content_container .cart_content .item_content div .item_image_content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 400px;
  margin-right: 2em;
}
.cart_page .cart_content_container .cart_content .item_content div .item_image_content p {
  font-weight: 600;
  color: #07130c;
  width: 100%;
}
.cart_page .cart_content_container .cart_content .item_content div .item_image {
  height: 100px;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  cursor: pointer;
}
.cart_page .cart_content_container .cart_content .item_content p {
  font-weight: 600;
  color: #07130c;
  text-align: left;
  margin-right: 1em;
}
.cart_page .cart_content_container .cart_content .item_content p:nth-last-of-type(1) {
  font-weight: 400;
  color: rgba(7, 19, 12, 0.3);
}
.cart_page .cart_content_container .cart_content .item_content .item_price {
  font-weight: 400;
  font-size: clamp(14px, 5vw, 12px);
}
.cart_page .cart_content_container .subtotal_container {
  display: flex;
  flex-direction: column;
  width: 40%;
  padding: 0 1em;
  gap: 1em;
  background-color: rgba(7, 19, 12, 0.05);
}
.cart_page .cart_content_container .subtotal_container .clear_cart_btn {
  font-weight: 400;
}
.cart_page .cart_content_container .subtotal_container .check_out_btn {
  background-color: #51ce8b !important;
  color: #07130c;
  font-weight: 600;
}

@media (min-width: 1900px) {
  .cart_page {
    padding: 2em 30em;
  }
}
@media (max-width: 748px) {
  .cart_page {
    padding: 2em 20px;
  }
  .cart_page .cart_logo_container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .cart_page .cart_content_container {
    flex-direction: column;
    gap: 2em;
    margin: 0;
  }
  .cart_page .cart_content_container .cart_content {
    max-width: 100%;
  }
  .cart_page .cart_content_container .cart_content .item_content {
    flex-direction: row;
    width: 100%;
    padding: 8px 0;
  }
  .cart_page .cart_content_container .cart_content .item_content div {
    width: 50%;
  }
  .cart_page .cart_content_container .cart_content .item_content div select {
    width: 150px;
  }
  .cart_page .cart_content_container .cart_content .item_content div .quantity_container {
    display: flex;
    flex-direction: row;
    gap: 4px;
  }
  .cart_page .cart_content_container .cart_content .item_content .item_image_content {
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 100%;
  }
  .cart_page .cart_content_container .subtotal_container {
    width: 100%;
  }
}/*# sourceMappingURL=cartStyles.module.css.map */