@use "../variables";

.ship_policies_container {
  max-width: 100vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: variables.$primary-white;
  padding: 2em 10em;
  gap: 2em;

  a {
    color: #0000ff;
    text-decoration: underline;
    svg {
      margin-top: 150px;
      width: auto;
      position: relative;
      height: auto;
    }
  }
  h1 {
    width: 100%;
    border-bottom: 1px solid rgba(variables.$primary_black, 0.6);
    font-size: variables.$h4_font_size;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 1900px) {
  .ship_policies_container {
    padding: 2em 40em !important;
  }
}
@media (max-width: 1024px) {
  .ship_policies_container {
    padding: 2em 8em;
  }
}
@media (max-width: 728px) {
  .ship_policies_container {
    padding: 2em 2em;
  }
}
