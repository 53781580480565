@use "../variables";

.calander_container {
  max-width: 100vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: variables.$primary-white;
  padding: 2em 10em;
  text-align: center;
  gap: 8px;

  a {
    svg {
      margin-top: 150px;
      width: auto;
      position: relative;
      height: auto;
    }
  }

  bl {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: auto;
    flex-direction: column;
    gap: 8px;
    li {
      text-align: left;
    }
  }
}

@media (min-width: 1900px) {
  .calander_container {
    padding: 2em 40em !important;
  }
}

@media (max-width: 1024px) {
  .calander_container {
    padding: 2em 2em;
  }
}
