.home_page_container {
  display: flex;
  flex-direction: column;
  background-color: #fcfaf7;
  max-width: 100vw;
  padding: 2em 8em !important;
}
.home_page_container .main_section {
  background-position: center;
  background-size: cover;
  max-width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5vh;
  margin: 125px 0 0 0;
}
.home_page_container .main_section h1 {
  text-align: center;
}
.home_page_container .main_section .main_content_container {
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 350px;
  background-color: rgba(81, 206, 139, 0.3);
}
.home_page_container .main_section .main_content_container .main_image1 {
  background-image: url(../../../images/main_section_image1.jpg);
  background-size: cover;
  background-position: 50% 75%;
  width: 75%;
  box-shadow: 1px 3px 20px rgba(7, 19, 12, 0.1);
  height: 100%;
}
.home_page_container .main_section .main_content_container .main_image2 {
  background-image: url(../../../images/main_section_image2.jpg);
  background-size: cover;
  background-position: 50% 15%;
  width: 100%;
  box-shadow: 1px 3px 20px rgba(7, 19, 12, 0.1);
  height: 100%;
}
.home_page_container .main_section .main_content_container h1 {
  font-weight: 200;
  font-size: clamp(24px, 5vw, 54px);
  letter-spacing: -2px;
  text-align: center;
}
.home_page_container .main_section .searchbar_section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  place-items: center;
  width: 100%;
  max-height: 100%;
}
.home_page_container .main_section .searchbar_section .logo_white {
  width: autu;
  max-height: 300px;
}
.home_page_container .main_section .searchbar_section h2 {
  max-width: 80vw;
  font-size: clamp(24px, 5vw, 54px);
  font-weight: 600;
  letter-spacing: -2px;
  color: #fcfaf7;
  text-align: center;
  padding: 12px 12px;
}
.home_page_container .main_section .searchbar_section form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}
.home_page_container .main_section .searchbar_section form .search_btn {
  padding: 1em 2em;
  position: relative;
}
.home_page_container .main_section .searchbar_section form .shipping_address_input {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  min-width: 60vw;
  width: 100%;
  border-top: 1px solid rgba(7, 19, 12, 0.2);
  border-right: 3px solid rgba(7, 19, 12, 0.2);
  border-left: 1px solid rgba(7, 19, 12, 0.2);
  border-bottom: 3px solid rgba(7, 19, 12, 0.2);
  border-radius: 5px;
}
.home_page_container .main_section .searchbar_section form .shipping_address_input input {
  font-size: clamp(14px, 5vw, 24px);
  background-color: #fcfaf7;
  display: flex;
  border: none;
  height: clamp(25px, 5vw, 100px);
  width: 100%;
  border-radius: 5px;
  min-height: 2em;
  cursor: pointer;
}
.home_page_container .main_section .searchbar_section form .shipping_address_input input::-moz-placeholder {
  font-size: 100%;
  text-align: center;
  position: absolute;
  font-weight: 500;
}
.home_page_container .main_section .searchbar_section form .shipping_address_input input::placeholder {
  font-size: 100%;
  text-align: center;
  position: absolute;
  font-weight: 500;
}
.home_page_container .main_section .searchbar_section form .shipping_address_input .search_icon {
  position: relative;
  left: 10px;
  height: 100%;
  opacity: 0.5;
}
.home_page_container .main_section .searchbar_section form .shipping_address_input svg {
  position: absolute;
  right: 10px;
  height: 100%;
}
.home_page_container .main_section .searchbar_section form .shipping_address_input .suggestion_list {
  position: absolute;
  width: 95%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0 20px 12px 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: clamp(14px, 5vw, 16px);
  font-weight: 300;
  border-top: 1px solid #07130c;
  border-right: 3px solid #07130c;
  border-left: 1px solid #07130c;
  border-bottom: 3px solid #07130c;
  line-height: 1.5em;
  top: 100%;
  background-color: #fcfaf7;
  z-index: 1;
}
.home_page_container .main_section .shipping_address_input {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
}
.home_page_container .main_section .shipping_address_input form {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home_page_container .main_section .shipping_address_input form input {
  font-size: clamp(14px, 5vw, 16px);
  border-top: 1px solid #07130c;
  border-right: 3px solid #07130c;
  border-left: 1px solid #07130c;
  border-bottom: 3px solid #07130c;
  display: flex;
  height: clamp(25px, 5vw, 50px);
  width: 500px;
  padding: 10px;
  border-radius: 5px;
}
.home_page_container .main_section .shipping_address_input form input::-moz-placeholder {
  font-size: 1em;
  text-align: start;
  position: absolute;
}
.home_page_container .main_section .shipping_address_input form input::placeholder {
  font-size: 1em;
  text-align: start;
  position: absolute;
}
.home_page_container .main_section .shipping_address_input form svg {
  position: relative;
  right: 25px;
  cursor: pointer;
}
.home_page_container .main_section .shipping_address_input p.error_or_success {
  width: 100%;
  background-color: red;
}
.home_page_container .hero_container {
  max-width: 100vw;
  padding: 0 24px;
}
.home_page_container .hero_container .category_nav {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.home_page_container .hero_container .category_nav ul:nth-of-type(1) {
  width: 100%;
  padding: 12px 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-bottom: 1px solid rgba(7, 19, 12, 0.3);
}
.home_page_container .hero_container .category_nav ul.popular_category_nav {
  width: 100%;
  padding: 12px 20%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.home_page_container .hero_container .category_nav ul.popular_category_nav li {
  border-top: 1px solid #07130c;
  border-right: 2px solid #07130c;
  border-left: 1px solid #07130c;
  border-bottom: 2px solid #07130c;
  border-radius: 20px;
  padding: 10px 0;
  width: 125px;
  text-align: center;
}
.home_page_container .hero_container .hero_content {
  display: flex;
  align-items: center;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  padding: 0px 24px;
  max-width: 100%;
}
.home_page_container .hero_container .hero_content .hero_header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.home_page_container .hero_container .hero_content .hero_header h1 {
  line-height: 1.225em;
  font-size: clamp(36px, 5vw, 81px);
  font-weight: 300;
}
.home_page_container .hero_container .hero_content .hero_header span:nth-of-type(1) {
  font-weight: 600;
}
.home_page_container .hero_container .hero_content .hero_header span:nth-of-type(2) {
  font-weight: 600;
}
.home_page_container .hero_divider_container {
  display: flex;
  flex-direction: row;
  max-width: 100vw;
}
.home_page_container .hero_divider_container .green_box {
  display: flex;
  justify-content: flex-end;
  padding: 12px;
  background-color: #51ce8b;
  width: 25%;
}
.home_page_container .hero_divider_container .green_box h1 {
  font-weight: 700;
  font-size: clamp(24px, 5vw, 54px);
}
.home_page_container .hero_divider_container .yellow_box {
  padding: 12px;
  background-color: #f9d068;
  width: 75%;
}
.home_page_container .hero_divider_container .yellow_box h1 {
  font-weight: 700;
  font-size: clamp(24px, 5vw, 54px);
}
.home_page_container .product_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home_page_container .product_container .product_carousel {
  max-width: 75%;
}
.home_page_container .product_container .product_carousel div ul {
  position: static;
}
.home_page_container .selling_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 50vh;
  width: 100%;
  gap: 36px;
}
.home_page_container .selling_container .holiday_section_container {
  display: flex;
  gap: 12px;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-start;
  padding: 20px 20px;
  max-height: 100%;
  max-width: 100vw;
  background-color: rgba(81, 206, 139, 0.3);
}
.home_page_container .selling_container .holiday_section_container .image_container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.home_page_container .selling_container .holiday_section_container .image_container .holiday_image {
  height: 100%;
  width: 100%;
  padding: 0.5em;
}
.home_page_container .selling_container .holiday_section_container .content_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 50%;
  gap: 36px;
  justify-content: center;
  padding: 20px;
}
.home_page_container .selling_container .holiday_section_container .content_container .header {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 24px;
}
.home_page_container .selling_container .holiday_section_container .content_container .header h1 {
  line-height: 1em;
}
.home_page_container .selling_container .holiday_section_container .content_container .header h1 .bold_text_line {
  letter-spacing: -2px;
  font-size: clamp(36px, 5vw, 81px);
  font-weight: 600;
}
.home_page_container .selling_container .holiday_section_container .content_container .header h1 .thin_text_line {
  letter-spacing: -5px;
  font-size: clamp(36px, 5vw, 81px);
  font-weight: 300;
}
.home_page_container .selling_container .holiday_section_container .content_container .sub_header_container {
  display: flex;
  flex-direction: column;
  gap: 0.225em;
  justify-content: center;
  width: 50%;
}
.home_page_container .selling_container .holiday_section_container .content_container .sub_header_container .sub_header_yellow {
  width: 100%;
  padding: 0 24px;
}
.home_page_container .selling_container .holiday_section_container .content_container .sub_header_container .sub_header_yellow h5 {
  letter-spacing: -0.5px;
  line-height: 1em;
  font-size: clamp(14px, 5vw, 24px);
  font-weight: 200;
}
.home_page_container .selling_container .holiday_section_container .content_container .sub_header_container .sub_header_green {
  width: 100%;
  padding: 0 24px;
}
.home_page_container .selling_container .holiday_section_container .content_container .sub_header_container .sub_header_green h5 {
  letter-spacing: -1.5px;
  font-size: clamp(16px, 5vw, 36px);
  font-weight: 400;
  text-decoration: underline;
}
.home_page_container .selling_container h1 {
  font-size: clamp(24px, 5vw, 54px);
  width: 100%;
}
.home_page_container .selling_container h1 span {
  font-weight: 600;
}
.home_page_container .selling_container .best_selling_content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  flex: 1 0 0;
  gap: 10px;
}
.home_page_container .everyone_section_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  padding: 54px 20px;
  height: 100%;
  gap: 10px;
  background-color: rgba(252, 250, 247, 0.2);
}
.home_page_container .everyone_section_container .header_container {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 4;
}
.home_page_container .everyone_section_container .header_container h1 {
  text-align: center;
  font-weight: 600;
  font-size: clamp(24px, 5vw, 54px);
}
.home_page_container .everyone_section_container .content_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
}
.home_page_container .everyone_section_container .content_container .row_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
}
.home_page_container .everyone_section_container .content_container .row_container .row_content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
  width: 100%;
}
.home_page_container .everyone_section_container .content_container .row_container .row_content button {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: rgba(81, 206, 139, 0.1);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border: none;
}
.home_page_container .everyone_section_container .content_container .row_container .row_content button .everyone_button_img {
  background: url(../../../images/everyoneSection/for_her_img.jpg) lightgray 50%/cover no-repeat;
  background-position: 0% 50%;
  width: 250px;
  height: 250px;
}
.home_page_container .everyone_section_container .content_container .row_container .row_content .for_everyone_button {
  padding: 1em 3em;
}
.home_page_container .everyone_section_container .content_container .row_container:nth-of-type(1) .row_content button:nth-of-type(2) .everyone_button_img {
  background: url(../../../images/everyoneSection/for_him_img.jpg) lightgray 50%/cover no-repeat;
}
.home_page_container .everyone_section_container .content_container .row_container:nth-of-type(1) .row_content button:nth-of-type(3) .everyone_button_img {
  background: url(../../../images/everyoneSection/for_kids_img.jpg) lightgray 50%/cover no-repeat;
}
.home_page_container .everyone_section_container .content_container .row_container:nth-of-type(1) .row_content button:nth-of-type(4) .everyone_button_img {
  background: url(../../../images/everyoneSection/for_teens_img.jpg) lightgray 50%/cover no-repeat;
}
.home_page_container .everyone_section_container .content_container .row_container:nth-of-type(2) .row_content button:nth-of-type(1) .everyone_button_img {
  background: url(../../../images/everyoneSection/for_dads_img.jpg) lightgray 50%/cover no-repeat;
}
.home_page_container .everyone_section_container .content_container .row_container:nth-of-type(2) .row_content button:nth-of-type(2) .everyone_button_img {
  background: url(../../../images/everyoneSection/for_moms_img.jpg) lightgray 50%/cover no-repeat;
}
.home_page_container .everyone_section_container .content_container .row_container:nth-of-type(2) .row_content button:nth-of-type(3) .everyone_button_img {
  background: url(../../../images/everyoneSection/for_business.jpg) lightgray 50%/cover no-repeat;
}
.home_page_container .everyone_section_container .content_container .row_container:nth-of-type(2) .row_content button:nth-of-type(4) .everyone_button_img {
  background: url(../../../images/everyoneSection/for_everyone_img.png) lightgray 50%/cover no-repeat;
}
.home_page_container .tech_section_container {
  display: flex;
  flex-direction: row;
  max-width: 100vw;
  height: 100%;
  justify-content: space-between;
  padding: 54px 24px;
  background-color: rgba(252, 250, 247, 0.3);
}
.home_page_container .tech_section_container .content_row_container:nth-of-type(1) {
  width: 100%;
}
.home_page_container .tech_section_container .content_row_container:nth-of-type(1) .gift_with_love_img {
  height: 400px;
  width: 95%;
  background: url("../../../images/tech-section/love_section_img.jpg") lightgray 50%/cover no-repeat;
}
.home_page_container .tech_section_container .content_row_container {
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  gap: 0.8em;
  flex-shrink: 1;
  gap: 24px;
}
.home_page_container .tech_section_container .content_row_container h3 {
  font-size: clamp(36px, 5vw, 81px);
  font-weight: 300;
  letter-spacing: -1.5px;
  padding: 0 24px;
  line-height: 1em;
  text-align: center;
  padding: 0.225em 0;
}
.home_page_container .tech_section_container .content_row_container h3 span.span_bold {
  font-weight: 600;
}
.home_page_container .tech_section_container .content_row_container .sub_header_container .sub_header_yellow {
  width: 100%;
}
.home_page_container .tech_section_container .content_row_container .sub_header_container .sub_header_yellow button {
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  display: flex;
  align-items: flex-start;
}
.home_page_container .tech_section_container .content_row_container .sub_header_container .sub_header_yellow button h3 {
  font-size: clamp(14px, 5vw, 24px);
  font-weight: 300;
}
.home_page_container .tech_section_container .content_row_container .sub_header_container .sub_header_green {
  width: 100%;
}
.home_page_container .tech_section_container .content_row_container .sub_header_container .sub_header_green button {
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  display: flex;
  align-items: flex-start;
}
.home_page_container .tech_section_container .content_row_container .sub_header_container .sub_header_green button h3 {
  font-size: clamp(16px, 5vw, 36px);
  font-weight: 500;
  text-decoration: underline;
}
.home_page_container .gift_type_section_container {
  max-width: 100vw;
  width: 100%;
  padding: 54px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.home_page_container .gift_type_section_container .header_container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em 0;
  width: -moz-fit-content;
  width: fit-content;
}
.home_page_container .gift_type_section_container .header_container .header {
  font-size: clamp(24px, 5vw, 54px);
}
.home_page_container .gift_type_section_container .header_container .header .bold_text {
  font-weight: 600;
}
.home_page_container .gift_type_section_container .content_container {
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
}
.home_page_container .gift_type_section_container .content_container .content_column_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 24px;
}
.home_page_container .gift_type_section_container .content_container .content_column_container .list_header_container {
  width: 100%;
  padding: 4px 8px;
  text-align: center;
  border-top: 1px solid #07130c;
  border-right: 3px solid #07130c;
  border-left: 1px solid #07130c;
  border-bottom: 3px solid #07130c;
  background-color: rgba(249, 232, 191, 0.6);
}
.home_page_container .gift_type_section_container .content_container .content_column_container .list_header_container h5 {
  font-weight: 400;
}
.home_page_container .gift_type_section_container .content_container .content_column_container ul {
  padding: 8px 24px;
}

@media (min-width: 1900px) {
  .home_page_container {
    padding: 2em 25em !important;
  }
}
@media (max-width: 1024px) {
  .home_page_container {
    padding: 2em 2em !important;
  }
  .home_page_container .main_section .main_content_container {
    height: 300px;
  }
  .home_page_container .main_section .main_content_container .main_image2 {
    display: none;
  }
  .home_page_container .main_section .main_content_container .main_image1 {
    width: 100%;
  }
  .home_page_container .everyone_section_container {
    flex-direction: column;
    gap: 1em;
  }
  .home_page_container .everyone_section_container .header_container h1 {
    text-align: center;
  }
  .home_page_container .everyone_section_container .content_container .row_container .row_content button .everyone_button_img {
    background: url(../../../images/everyoneSection/for_her_img.jpg) lightgray 50%/cover no-repeat;
    background-position: 0% 50%;
    width: 40vw;
    height: 300px;
  }
  .home_page_container .gift_type_section_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
  }
  .home_page_container .gift_type_section_container .header_container h3 {
    font-size: clamp(36px, 5vw, 81px);
    text-align: center;
  }
  .home_page_container .selling_container .holiday_section_container {
    flex-direction: column-reverse;
  }
  .home_page_container .selling_container .holiday_section_container .content_container {
    width: 100%;
  }
  .home_page_container .selling_container .holiday_section_container .content_container .header {
    justify-content: center;
    width: 100%;
  }
  .home_page_container .selling_container .holiday_section_container .content_container .header h1 {
    text-align: center;
    width: 100%;
  }
  .home_page_container .selling_container .holiday_section_container .content_container .header h1 span.thin_text_line {
    letter-spacing: -2px;
  }
  .home_page_container .selling_container .holiday_section_container .content_container .header h1 br {
    display: none;
  }
  .home_page_container .selling_container .holiday_section_container .content_container .sub_header_container {
    width: 100%;
  }
  .home_page_container .selling_container .holiday_section_container .content_container .sub_header_container div h5 {
    text-align: center;
    width: 100%;
  }
}
@media (max-width: 728px) {
  br {
    display: none;
  }
  .home_page_container {
    padding: 2em 2em !important;
  }
  .home_page_container .main_section .searchbar_section form {
    width: 80%;
    flex-direction: column;
  }
  .home_page_container .main_section .main_content_container .main_image1 {
    height: 250px;
  }
  .home_page_container .gift_type_section_container .content_container {
    padding: 0;
  }
  .home_page_container .gift_type_section_container .content_container .content_column_container {
    padding: 0;
  }
  .home_page_container .tech_section_container {
    flex-direction: column-reverse;
    padding: 0;
    align-items: center;
    gap: 10px;
  }
  .home_page_container .tech_section_container .content_row_container {
    width: 100%;
    align-items: center;
  }
  .home_page_container .tech_section_container .content_row_container .sub_header_container .sub_header_yellow button {
    justify-content: center;
    align-items: center;
  }
  .home_page_container .tech_section_container .content_row_container .sub_header_container .sub_header_yellow button h3 {
    text-align: center;
  }
  .home_page_container .tech_section_container .content_row_container .sub_header_container .sub_header_green button {
    justify-content: center;
    align-items: center;
  }
  .home_page_container .tech_section_container .content_row_container .sub_header_container .sub_header_green button h3 {
    text-align: center;
  }
  .home_page_container .everyone_section_container .content_container .row_container .row_content {
    flex-direction: row;
  }
  .home_page_container .everyone_section_container .content_container .row_container .row_content button {
    width: 40vw;
  }
  .home_page_container .everyone_section_container .content_container .row_container .row_content button .everyone_button_img {
    background: url(../../../images/everyoneSection/for_her_img.jpg) lightgray 50%/cover no-repeat;
    background-position: 0% 50%;
    width: 100%;
    max-height: 250px;
  }
}
@media (max-width: 529px) {
  .home_page_container {
    padding: 0 8px !important;
  }
  .home_page_container .main_section {
    margin: 15vh 0 0 0;
  }
  .home_page_container .main_section h1 {
    letter-spacing: -0.5px;
  }
  .home_page_container .main_section h4 {
    text-align: center;
  }
  .home_page_container .main_section .main_content_container {
    height: 200px;
    display: none;
  }
  .home_page_container .main_section .main_content_container .main_image1 {
    height: 100%;
  }
  .home_page_container .main_section .searchbar_section {
    width: 100%;
  }
  .home_page_container .main_section .searchbar_section .logo_white {
    width: 70vw;
  }
  .home_page_container .main_section .searchbar_section form {
    width: 100%;
  }
  .home_page_container .main_section .searchbar_section form .shipping_address_input {
    width: 100%;
  }
  .home_page_container .main_section .searchbar_section form .shipping_address_input input {
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: clamp(14px, 5vw, 16px);
  }
  .home_page_container .hero_container {
    padding: 0;
  }
  .home_page_container .hero_container .hero_content {
    flex-direction: column;
    padding: 0;
  }
  .home_page_container .hero_container .hero_content .hero_header h1 {
    text-align: center;
  }
  .home_page_container .hero_container .hero_content .hero_image img {
    max-width: 100%;
  }
  .home_page_container .selling_container {
    padding: 0;
  }
  .home_page_container .selling_container .holiday_section_container {
    padding: 0;
    flex-direction: column-reverse;
    gap: 10px;
  }
  .home_page_container .selling_container .holiday_section_container .content_container {
    width: 100%;
  }
  .home_page_container .selling_container .holiday_section_container .content_container .header {
    padding: 0;
  }
  .home_page_container .selling_container .holiday_section_container .content_container .header h1 {
    text-align: center;
    line-height: 1em;
  }
  .home_page_container .selling_container .holiday_section_container .content_container .header h1 span.bold_text_line {
    letter-spacing: 0px;
  }
  .home_page_container .selling_container .holiday_section_container .content_container .header h1 span.thin_text_line {
    letter-spacing: -0.5px;
  }
  .home_page_container .selling_container .holiday_section_container .content_container .sub_header_yellow h5 {
    letter-spacing: -0.5px;
  }
  .home_page_container .selling_container .holiday_section_container .content_container .sub_header_green h5 {
    letter-spacing: -0.5px;
  }
  .home_page_container .selling_container .best_selling_content {
    justify-content: center;
  }
  .home_page_container .everyone_section_container {
    padding: 0;
    max-width: 100%;
  }
  .home_page_container .everyone_section_container .header_container {
    width: 100%;
  }
  .home_page_container .everyone_section_container .content_container .row_container {
    width: 100%;
    display: block;
  }
  .home_page_container .everyone_section_container .content_container .row_container .row_content {
    flex-direction: column;
  }
  .home_page_container .everyone_section_container .content_container .row_container .row_content button {
    width: 100%;
  }
  .home_page_container .tech_section_container {
    flex-direction: column-reverse;
    padding: 0;
    align-items: center;
  }
  .home_page_container .tech_section_container .content_row_container {
    width: 100%;
    align-items: center;
  }
  .home_page_container .gift_type_section_container .content_container {
    padding: 0;
    flex-direction: column;
  }
  .home_page_container .gift_type_section_container .content_container .content_column_container {
    padding: 0;
    align-items: center;
    text-align: center;
  }
  .home_page_container .gift_type_section_container .content_container .content_column_container li {
    padding: 4px 0;
  }
}/*# sourceMappingURL=homePage.module.css.map */