.footer_container {
  max-width: 100vw;
  background: #07130c;
  position: sticky !important;
}
.footer_container p {
  padding: 0 0 54px 54px;
  color: #fcfaf7;
}
.footer_container .contact_bar {
  border-top: 1px solid #fcfaf7;
  border-bottom: 1px solid #fcfaf7;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.footer_container .contact_bar .contact_btn_container {
  display: flex;
  padding: 24px 54px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.footer_container .contact_bar .contact_btn_container .ancor_tag {
  font-size: clamp(14px, 5vw, 24px);
  font-weight: 300;
  letter-spacing: -1.5px;
  color: #fcfaf7;
}
.footer_container .contact_bar .contact_btn_container svg {
  cursor: pointer;
  color: #fcfaf7;
}
.footer_container .contact_bar .newsletter_footer_signup {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  max-width: 50%;
  width: 100%;
}
.footer_container .contact_bar .newsletter_footer_signup .shipping_address_input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 10px 24px;
  width: 100%;
}
.footer_container .contact_bar .newsletter_footer_signup .shipping_address_input form {
  position: relative;
  width: 100%;
}
.footer_container .contact_bar .newsletter_footer_signup .shipping_address_input form input {
  font-size: clamp(14px, 5vw, 16px);
  color: #07130c;
  border-top: 1px solid #fcfaf7;
  border-right: 3px solid #fcfaf7;
  border-left: 1px solid #fcfaf7;
  border-bottom: 3px solid #fcfaf7;
  display: flex;
  height: clamp(25px, 5vw, 50px);
  width: 100%;
  padding: 24px;
  border-radius: 5px;
}
.footer_container .contact_bar .newsletter_footer_signup .shipping_address_input form input::-moz-placeholder {
  font-size: 1em;
  text-align: start;
  position: absolute;
}
.footer_container .contact_bar .newsletter_footer_signup .shipping_address_input form input::placeholder {
  font-size: 1em;
  text-align: start;
  position: absolute;
}
.footer_container .contact_bar .newsletter_footer_signup .shipping_address_input form svg {
  position: relative;
  left: 100%;
  display: none;
  color: #fcfaf7;
  cursor: pointer;
}
.footer_container .contact_bar .newsletter_footer_signup .shipping_address_input form .input_button {
  padding: 0.6em 2em;
  background: #fcfaf7;
  color: #07130c;
  height: 100%;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  border: 1px solid #07130c;
}
.footer_container .footer_navigation {
  display: flex;
  padding: 24px 54px;
  gap: 10px;
  align-self: flex-start;
}
.footer_container .footer_navigation .footer_nav_section {
  display: flex;
  align-self: center;
  flex-direction: column;
  flex: 1 0 0;
  padding: 0 0;
}
.footer_container .footer_navigation .footer_nav_section .section_header {
  display: flex;
  padding: 0px 0;
  align-items: center;
  border-bottom: 1px solid #fcfaf7;
  color: #fcfaf7;
}
.footer_container .footer_navigation .footer_nav_section .section_list {
  display: flex;
  gap: 4px;
  flex-direction: column;
  align-self: stretch;
  justify-content: center;
}
.footer_container .footer_navigation .footer_nav_section:nth-of-type(1) {
  display: flex;
}
.footer_container .footer_navigation .footer_nav_section:nth-of-type(1) .section_header {
  display: flex;
  align-items: flex-start;
  padding: 8px 0;
  border-bottom: 1px solid #fcfaf7;
}
.footer_container .footer_navigation .footer_nav_section:nth-of-type(1) .section_list {
  display: flex;
  gap: 4px;
  flex-direction: row;
  align-self: flex-start;
  justify-content: center;
}
.footer_container .footer_navigation .footer_nav_section:nth-of-type(1) .section_list li {
  color: #fcfaf7 !important;
}
.footer_container .footer_navigation .footer_nav_section:nth-of-type(1) .section_list li a {
  color: #fcfaf7 !important;
}
.footer_container .footer_navigation:nth-of-type(2) .footer_nav_section:nth-of-type() {
  flex-direction: row-reverse;
  width: 100%;
}
.footer_container .footer_navigation:nth-of-type(2) {
  display: flex;
  padding: 24px 54px;
  align-items: flex-start;
  gap: 10px;
}
.footer_container .footer_navigation:nth-of-type(2) .footer_nav_section:nth-of-type(2) {
  display: flex;
  align-self: flex-start;
  gap: 8px;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  flex: 1 0 0;
}
.footer_container .footer_navigation:nth-of-type(2) .footer_nav_section:nth-of-type(2) .section_header:nth-of-type(2) {
  display: flex;
  align-items: flex-start;
  padding: 8px 0;
  border-bottom: 1px solid #fcfaf7;
}
.footer_container .footer_navigation:nth-of-type(2) .footer_nav_section:nth-of-type(2) .section_list:nth-of-type(01) {
  display: flex;
  gap: 4px;
  flex-direction: row;
  align-self: flex-start;
  justify-content: center;
}
.footer_container .footer_navigation:nth-of-type(2) .footer_nav_section:nth-of-type(2) .section_list:nth-of-type(01) li svg {
  height: 24px;
  cursor: pointer;
}
.footer_container .footer_navigation:nth-of-type(2) .footer_nav_section:nth-of-type(2) .section_list:nth-of-type(01) li svg g path {
  fill: #fcfaf7;
}
.footer_container .footer_navigation:nth-of-type(2) .footer_nav_section:nth-of-type(2) .section_list:nth-of-type(01) li path {
  fill: #fcfaf7;
}

@media (max-width: 728px) {
  .footer_container .contact_bar .newsletter_footer_signup {
    width: auto;
    flex-direction: column;
  }
  .footer_container .contact_bar .newsletter_footer_signup p {
    padding: 0;
  }
  .footer_container .footer_navigation {
    flex-direction: column;
  }
  .footer_container .footer_navigation .footer_nav_section {
    padding: 2% 5%;
  }
  .footer_container .footer_navigation:nth-of-type(2) {
    display: flex;
    padding: 24px 54px;
    align-items: flex-start;
    gap: 10px;
  }
  .footer_container .footer_navigation:nth-of-type(2) .footer_nav_section:nth-of-type(2) {
    display: flex;
    align-self: center;
    gap: 8px;
    flex-direction: column;
    flex: 1 0 0;
    padding: 24px 24px;
  }
  .footer_container .footer_navigation:nth-of-type(2) .footer_nav_section:nth-of-type(2) .section_header:nth-of-type(2) {
    display: flex;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid rgba(7, 19, 12, 0.3);
  }
  .footer_container .footer_navigation:nth-of-type(2) .footer_nav_section:nth-of-type(2) .section_list:nth-of-type(01) {
    display: flex;
    gap: 4px;
    flex-direction: row;
    align-self: center;
    justify-content: center;
  }
  .footer_container .footer_navigation:nth-of-type(2) .footer_nav_section:nth-of-type(2) .section_list:nth-of-type(01) li svg {
    height: 24px;
    cursor: pointer;
  }
  .footer_container .footer_navigation:nth-of-type(2) .footer_nav_section:nth-of-type(2) .section_list:nth-of-type(01) li svg g path {
    fill: #fcfaf7;
  }
  .footer_container .footer_navigation:nth-of-type(2) .footer_nav_section:nth-of-type(2) .section_list:nth-of-type(01) li path {
    fill: #fcfaf7;
  }
}
@media (max-width: 529px) {
  .footer_container .contact_bar {
    flex-direction: column;
  }
  .footer_container .contact_bar .contact_btn_container {
    justify-content: center;
  }
  .footer_container .contact_bar .newsletter_footer_signup {
    flex-direction: column;
    max-width: 100%;
  }
  .footer_container .contact_bar .newsletter_footer_signup .shipping_address_input {
    max-width: 50%;
  }
  .footer_container .contact_bar .newsletter_footer_signup .shipping_address_input form {
    display: flex;
    flex-direction: column;
  }
  .footer_container .contact_bar .newsletter_footer_signup .shipping_address_input form input.form-control {
    width: 100%;
  }
  .footer_container .footer_navigation {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .footer_container .footer_navigation .footer_nav_section {
    padding: 0;
    align-items: flex-start;
  }
  .footer_container .footer_navigation .footer_nav_section .section_header {
    text-align: center;
    width: 100%;
    justify-content: center;
  }
  .footer_container .footer_navigation .footer_nav_section:nth-of-type(2) {
    padding: 0 0;
    align-items: flex-start;
  }
  .footer_container .footer_navigation .section_list {
    justify-content: center;
  }
}/*# sourceMappingURL=footer.module.css.map */