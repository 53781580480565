.nav_container {
  max-width: 100vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 2;
  transition: all 0.3s;
  background-color: #fcfaf7;
}
.nav_container .info_nav_container {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
}
.nav_container .info_nav_container a {
  font-size: clamp(14px, 5vw, 12px);
  font-weight: 300;
}
.nav_container.show_nav {
  overflow: visible;
  transform: translateY(0px);
  height: auto;
}
.nav_container.hide_nav {
  overflow: hidden;
  transform: translateY(-700px);
}
.nav_container .nav_content {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  background-color: #fcfaf7;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  z-index: 2;
}
.nav_container .nav_content .country_selector {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav_container .nav_content .logo_black {
  height: 100%;
  width: auto;
  cursor: pointer;
  margin-left: 1em;
}
.nav_container .nav_content .shopping_bag_container {
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 75px;
  position: relative;
  margin-right: 20px;
  z-index: 1;
}
.nav_container .nav_content .shopping_bag_container .shopping_bag_content {
  top: 25px;
  height: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1em;
}
.nav_container .nav_content .shopping_bag_container .shopping_bag_content #text {
  height: 100%;
  cursor: pointer;
  height: 100%;
}
.nav_container .nav_content .shopping_bag_container .shopping_bag_content .shopping_bag {
  cursor: pointer;
  height: 100%;
}
.nav_container .nav_content .content_column_container {
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 1em;
  flex-direction: row;
  position: relative;
}
.nav_container .nav_content .content_column_container .x_icon {
  display: none;
}
.nav_container .nav_content .content_column_container .list_header_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  cursor: pointer;
  padding: 4px 12px;
  max-width: 100%;
  z-index: 1;
  border-right: 2px solid rgba(7, 19, 12, 0.5);
  background-color: #fcfaf7;
}
.nav_container .nav_content .content_column_container .list_header_container h5 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 500;
  text-align: center;
  font-size: clamp(14px, 5vw, 16px);
  letter-spacing: -0.5px;
  height: 80px;
  color: #07130c;
  width: 100%;
}
.nav_container .nav_content .content_column_container .hidden {
  width: auto;
  display: none;
  gap: 4px;
  height: auto;
  padding-bottom: 0.5em;
  transition: all 0.3s;
}
.nav_container .nav_content .content_column_container .show {
  display: flex;
  max-width: 1000px;
  width: 100%;
  min-width: 600px;
  position: absolute;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  top: 79px;
  z-index: 2;
  gap: 4px;
  transition: all 0.3s;
  background: #fcfaf7;
  border-right: 1px solid rgba(7, 19, 12, 0.5);
  border-bottom: 1px solid rgba(7, 19, 12, 0.5);
  border-left: 1px solid rgba(7, 19, 12, 0.5);
  flex-wrap: wrap;
}
.nav_container .nav_content .content_column_container .show .list_background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  background: rgba(252, 250, 247, 0.2);
  max-height: 400px;
  flex-wrap: wrap;
  gap: 24px;
  padding: 1em 1em 1em 1em;
}
.nav_container .nav_content .content_column_container .show .list_background .list_container {
  display: flex;
  flex-direction: column;
  width: auto;
  flex-wrap: wrap;
}
.nav_container .nav_content .content_column_container .show .list_background .list_container h5 {
  font-weight: 600;
  width: 100%;
  letter-spacing: -1.5px;
}
.nav_container .nav_content .content_column_container .show .list_background .list_container li {
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}
.nav_container .nav_content .content_column_container .show .list_background .list_container li a {
  display: flex;
  width: auto;
  height: auto;
  text-align: start;
  font-weight: 500;
  font-size: clamp(14px, 5vw, 12px);
  position: relative;
  z-index: 1;
  border-bottom: 1px solid rgba(7, 19, 12, 0.5);
}
.nav_container .nav_content .content_column_container .show .list_background .list_container li span.underline {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: black;
  transition: width 0.3s ease-in-out;
}
.nav_container .nav_content .content_column_container .show .list_background .list_container li:hover {
  transform: translateX(8px);
}
.nav_container .nav_content .content_column_container .show .list_background .list_container li:hover .underline {
  width: 100%;
}
.nav_container .nav_content .hamburger_menu {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  height: 25px;
  width: 30px;
  cursor: pointer;
  z-index: 10;
}
.nav_container .nav_content .hamburger_menu .hamburger_line {
  width: 100%;
  height: 2px;
  background-color: black;
  transition: all 0.3s ease-in-out;
}
.nav_container .nav_content .hamburger_menu .hamburger_line:nth-child(1) {
  transform: translateY(0);
}
.nav_container .nav_content .hamburger_menu .hamburger_line:nth-child(2) {
  transform: translateY(5px);
}
.nav_container .nav_content .hamburger_menu .hamburger_line:nth-child(3) {
  transform: translateY(10px);
}
.nav_container .nav_content .buttons_container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 4px;
  height: 80px;
}
.nav_container .nav_content .buttons_container a {
  padding: 4px 8px;
  border-radius: 10px;
  height: -moz-fit-content;
  height: fit-content;
  text-align: center;
}
.nav_container .nav_content .buttons_container .under_50,
.nav_container .nav_content .buttons_container .under_100,
.nav_container .nav_content .buttons_container .under_150 {
  border-radius: 20px;
  padding: 0.5em 1em;
  width: -moz-max-content;
  width: max-content;
  text-align: center;
  background-color: rgba(81, 206, 139, 0);
}
.nav_container .nav_content .buttons_container .sort_filter_container {
  display: flex;
  flex-direction: column;
}
.nav_container .nav_content .buttons_container .sort_filter_container .sort_filter_button {
  border: none;
  background: none;
  padding: 0;
}
.nav_container .nav_content .buttons_container .sort_filter_container span.underline {
  width: 100%;
  height: 1px;
  background: #07130c;
}

@media (max-width: 1024px) {
  .nav_container .nav_content {
    padding: 0 20px 0 0;
  }
  .nav_container .nav_content .logo_black {
    display: none;
  }
  .nav_container .nav_content .buttons_container {
    display: none;
  }
  .nav_container .nav_content .shopping_bag_container {
    height: 50px;
    position: absolute;
    width: 50px;
    top: 400px;
    right: -20px;
  }
  .nav_container .nav_content .shopping_bag_container .shopping_bag_content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: rgba(81, 206, 139, 0.5);
    padding: 4px;
    border-left: 1px solid #07130c;
    border-bottom: 1px solid #07130c;
    border-top: 1px solid #07130c;
  }
  .nav_container .nav_content .shopping_bag_container .shopping_bag_content .shopping_bag {
    padding: 0 0.5em;
    height: 50px;
    margin-right: 0px;
    position: relative;
    top: 0;
    width: 100%;
  }
  .nav_container .nav_content .shopping_bag_container .content_column_container {
    max-width: 125px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 1em;
    flex-wrap: wrap;
    flex-direction: column;
    position: relative;
    min-height: 200px;
  }
  .nav_container .nav_content .shopping_bag_container .content_column_container .list_header_container {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    cursor: pointer;
    padding: 4px 8px;
    text-align: center;
    width: 40%;
    z-index: 1;
  }
  .nav_container .nav_content .shopping_bag_container .content_column_container .list_header_container h5 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    text-align: center;
    font-size: clamp(14px, 5vw, 12px);
    letter-spacing: -0.5px;
    height: 70px;
    color: #07130c;
  }
  .nav_container .nav_content .logo_black {
    display: none;
  }
  .nav_container .nav_content .buttons_container .under_50,
  .nav_container .nav_content .buttons_container .under_150,
  .nav_container .nav_content .buttons_container .under_100 {
    display: none;
  }
}
@media (max-width: 786px) {
  .nav_container {
    height: 80px;
  }
  .nav_container .nav_content {
    height: 80px;
  }
  .nav_container .nav_content .content_column_container {
    padding: 0;
  }
  .nav_container .nav_content .content_column_container .list_header_container {
    height: 80px;
    padding: 4px 0 0 0;
    width: 100%;
  }
  .nav_container .nav_content .content_column_container .list_header_container h5 {
    font-size: clamp(14px, 5vw, 12px);
  }
  .nav_container .nav_content .nav_items {
    width: 100%;
    justify-content: space-evenly;
  }
  .nav_container .nav_content .nav_items .nav_item {
    width: auto;
  }
  .nav_container .nav_content .nav_items .nav_item svg {
    max-height: 15px;
  }
  .nav_container .nav_content .nav_items .nav_item .ancor_container a.ancor_tag {
    font-size: clamp(14px, 5vw, 12px);
    padding: 0;
  }
  .nav_container .nav_content .nav_items .search_icon {
    width: -moz-min-content;
    width: min-content;
  }
  .nav_container .nav_content .shipping_address_input {
    display: none;
  }
  .nav_container .category_nav ul:nth-of-type(2) li {
    width: 20em;
    padding: 0;
  }
  .nav_container .category_nav ul:nth-of-type(2) li a {
    font-size: clamp(14px, 5vw, 12px);
  }
}
@media (max-width: 529px) {
  .nav_container #buttom_nav_box {
    display: none !important;
  }
  .nav_container .logo_black {
    display: flex !important;
    width: 50% !important;
  }
  .nav_container .nav_content .shopping_bag_container {
    left: 7em;
  }
  .nav_container .nav_content a {
    width: 40%;
  }
  .nav_container .nav_content a .logo_black {
    display: flex;
    width: 80% !important;
  }
  .nav_container .nav_content .shopping_bag_container {
    height: 50px;
    position: none;
    width: 100px;
    top: -10px;
    right: 0;
    margin-right: 4em;
  }
  .nav_container .nav_content .shopping_bag_container .shopping_bag_content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: none;
    padding: 4px;
    border: none;
  }
  .nav_container .nav_content .shopping_bag_container .shopping_bag_content .shopping_bag {
    padding: 0;
    height: 50px;
    width: auto;
    margin-right: 0px;
    position: relative;
    top: 0;
  }
  .nav_container .nav_content .content_column_container {
    flex-direction: column;
    top: 80px;
    padding: 0;
    background-color: #fcfaf7;
    width: 100vw;
    position: absolute;
  }
  .nav_container .nav_content .content_column_container .list_header_container {
    height: 80px;
    padding: 4px 4px;
    width: 100%;
  }
  .nav_container .nav_content .content_column_container .list_header_container h5 {
    font-size: clamp(14px, 5vw, 16px);
  }
  .nav_container .nav_content .content_column_container .list_header_container:nth-of-type(4) {
    border-bottom: 1px solid rgba(7, 19, 12, 0.5);
  }
  .nav_container .nav_content .content_column_container .list_container a {
    width: 150px !important;
    border-bottom: solid 1px rgba(7, 19, 12, 0.5);
  }
  .nav_container .nav_content .content_column_container .show {
    top: 0;
    min-width: 100vw;
  }
  .nav_container .nav_content .content_column_container .x_icon {
    left: 0;
    display: inline;
    position: absolute;
    z-index: 10;
  }
  .nav_container .nav_content .hamburger_menu {
    display: none;
    position: relative;
    width: 40px;
    top: 20px;
  }
  .nav_container .nav_content .nav_items {
    width: 100%;
    justify-content: space-evenly;
  }
  .nav_container .nav_content .nav_items .nav_item {
    width: auto;
  }
  .nav_container .nav_content .nav_items .nav_item svg {
    max-height: 15px;
  }
  .nav_container .nav_content .nav_items .nav_item .ancor_container a.ancor_tag {
    font-size: clamp(14px, 5vw, 12px);
    padding: 0;
  }
  .nav_container .nav_content .nav_items .search_icon {
    width: -moz-min-content;
    width: min-content;
  }
  .nav_container .nav_content .shipping_address_input {
    display: none;
  }
  .nav_container .category_nav ul:nth-of-type(1) {
    display: none;
  }
  .nav_container .category_nav ul:nth-of-type(2) {
    padding: 1em 0.5em;
    justify-content: space-between;
  }
  .nav_container .category_nav ul:nth-of-type(2) li {
    width: 100%;
  }
  .nav_container .category_nav ul:nth-of-type(2) li a {
    font-size: clamp(14px, 5vw, 12px);
    text-transform: lowercase;
  }
}/*# sourceMappingURL=navBar.module.css.map */