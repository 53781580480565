.item_page_container {
  background-color: #fcfaf7;
  max-width: 100vw;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 2em 8em;
}
.item_page_container .logo {
  margin-top: 150px;
  width: auto;
  position: relative;
  height: auto;
}
.item_page_container .item_content_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: min(100%, 80vh);
}
.item_page_container .item_content_container .item_image_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  padding: 36px 0;
  height: max(80vh, 800px);
  position: relative;
}
.item_page_container .item_content_container .item_image_container span.background_color {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}
.item_page_container .item_content_container .item_image_container span.background_color .item_image {
  height: 100%;
  max-width: 100%;
  width: 100%;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  box-shadow: rgba(7, 19, 12, 0.1) 0px 24px 24px 0px;
}
.item_page_container .item_content_container .item_information_container {
  width: 50%;
  padding: 4em;
  gap: 1em;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}
.item_page_container .item_content_container .item_information_container .item_product_id {
  color: rgba(7, 19, 12, 0.3);
}
.item_page_container .item_content_container .item_information_container .item_product_name {
  font-size: clamp(24px, 5vw, 54px);
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 1.225em;
  padding: 0 0 0.225em 0;
  border-bottom: solid 1px rgba(7, 19, 12, 0.5);
}
.item_page_container .item_content_container .item_information_container .discription_container {
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 1em 0 1em 0;
  gap: 0.5em;
}
.item_page_container .item_content_container .item_information_container .discription_container h5 {
  font-weight: 400;
}
.item_page_container .item_content_container .item_information_container .discription_container .item_discription {
  width: 100%;
  position: relative;
  font-size: clamp(14px, 5vw, 12px);
  line-height: 1.5em;
}
.item_page_container .item_content_container .item_information_container .price_container {
  padding: 1em 0 1.5em 0;
}
.item_page_container .item_content_container .item_information_container .price_container .item_price {
  font-weight: 500;
  font-size: clamp(14px, 5vw, 24px);
}
.item_page_container .item_content_container .item_information_container .price_container span.rating_container .star_icon {
  margin-bottom: 1em;
  cursor: pointer;
}
.item_page_container .item_content_container .item_information_container .price_container .add_cart_container {
  width: 100%;
  display: flex;
  gap: 0.5em;
}
.item_page_container .item_content_container .item_information_container .price_container .add_cart_container .qty_container {
  display: flex;
  height: 1.5em;
  gap: 0.5em;
}
.item_page_container .item_content_container .item_information_container .price_container .add_cart_container span {
  display: flex;
  gap: 4px;
}
.item_page_container .item_content_container .item_information_container .price_container .add_cart_container span label {
  font-size: clamp(14px, 5vw, 16px);
  color: rgba(7, 19, 12, 0.5);
  font-weight: 300;
}
.item_page_container .item_content_container .item_information_container .price_container .add_cart_container button {
  width: 100%;
  border: none;
  background: #f04141;
  color: #fcfaf7;
}
.item_page_container .item_content_container .item_information_container .image_carousel_container {
  padding: 2em 0 0 0;
  height: 100%;
}
.item_page_container .item_content_container .item_information_container .image_carousel_container div {
  height: 100%;
}
.item_page_container .item_content_container .item_information_container .image_carousel_container div div {
  height: auto;
  display: flex;
}
.item_page_container .item_content_container .item_information_container .image_carousel_container div div .item_image {
  height: auto;
  width: 200px;
}
.item_page_container .any_gift_section {
  padding: 2em 0em 2em 0em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 4em;
}
.item_page_container .any_gift_section h4 {
  font-size: clamp(16px, 5vw, 36px);
  text-align: center;
  padding: 0 0 1em 0;
  width: 100%;
  border-bottom: solid 1px rgba(7, 19, 12, 0.5);
}
.item_page_container .any_gift_section div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item_page_container .any_gift_section div div div {
  max-height: 300px;
}
.item_page_container .any_gift_section div div div img {
  height: 100%;
  width: 80% !important;
  position: relative;
}
.item_page_container .review_section {
  padding: 2em 0em 0 0em;
  width: 100%;
  border-bottom: solid 1px rgba(7, 19, 12, 0.5);
}
.item_page_container .review_section h3 {
  text-align: center;
  font-size: clamp(24px, 5vw, 54px);
  border-bottom: solid 1px rgba(7, 19, 12, 0.5);
}
.item_page_container .review_section .review_form {
  display: flex;
  flex-direction: column;
}
.item_page_container .review_section .review_button {
  border: none;
  background: #07130c;
  color: #fcfaf7;
  font-size: clamp(14px, 5vw, 16px);
  padding: 0.225em 1em;
}
.item_page_container .review_section .review_container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  width: 100%;
  flex-direction: column;
  gap: 2em;
}
.item_page_container .review_section .review_container .post_content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;
}

@media (min-width: 1800px) {
  .item_page_container {
    padding: 2em 25em !important;
  }
}
@media (max-width: 1024px) {
  .item_page_container .item_content_container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .item_page_container .item_content_container .item_image_container {
    width: 100%;
    max-height: 800px;
  }
  .item_page_container .item_content_container .item_information_container {
    width: 100%;
    padding: 2em 2em 2em 2em;
  }
  .item_page_container .item_content_container .item_information_container .discription_container {
    padding: 0;
    gap: 0.5em;
  }
  .item_page_container .item_content_container .item_information_container .discription_container h5 {
    padding: 8px;
  }
}
@media (max-width: 748px) {
  .item_page_container {
    padding: 2em 8px;
  }
  .item_page_container .item_content_container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .item_page_container .item_content_container .item_image_container {
    width: 100%;
    height: auto;
  }
  .item_page_container .item_content_container .item_information_container {
    width: 100%;
    padding: 2em 2em 2em 2em;
  }
  .item_page_container .item_content_container .item_information_container .discription_container {
    padding: 0;
    gap: 0.5em;
  }
  .item_page_container .item_content_container .item_information_container .discription_container h5 {
    padding: 8px;
  }
  .item_page_container .item_content_container .item_information_container .any_gift_section {
    padding: 4em 0 2em 0;
    border-bottom: solid 1px rgba(7, 19, 12, 0.5);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .item_page_container .item_content_container .item_information_container .any_gift_section h3 {
    font-size: clamp(16px, 5vw, 36px);
    text-align: center;
    padding: 0 0 1em 0;
  }
  .item_page_container .item_content_container .item_information_container .any_gift_section div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .item_page_container .item_content_container .item_information_container .any_gift_section div div {
    height: 100%;
  }
  .item_page_container .item_content_container .item_information_container .any_gift_section div div img {
    height: 100%;
    width: 100% !important;
  }
}
@media (max-width: 564px) {
  .item_page_container .logo {
    width: 100%;
    position: relative;
  }
  .item_page_container .item_content_container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .item_page_container .item_content_container .item_image_container {
    width: 100%;
    height: auto;
    padding: 0;
  }
  .item_page_container .item_content_container .item_information_container {
    width: 100%;
    padding: 2em 8px;
  }
  .item_page_container .item_content_container .item_information_container .discription_container {
    padding: 0;
    gap: 0.5em;
  }
  .item_page_container .item_content_container .item_information_container .discription_container h5 {
    padding: 8px;
  }
}/*# sourceMappingURL=itemPage.module.css.map */