@use "../variables";

.checkout_form {
  min-height: 100vh;
  margin-top: 80px;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 2em;
  background-color: variables.$primary_white;
  min-height: 75vh;

  a {
    width: 100%;
    display: flex;
    justify-content: center;
    svg {
      margin-top: 150px;
      width: auto;
      position: relative;
      height: auto;
    }
  }

  .banner_container {
    padding: 0 15em;
  }

  .banner_container:nth-of-type(3) {
    margin-bottom: 3em !important;
  }
}

@media (min-width: 1900px) {
  .checkout_form {
    .banner_container {
      padding: 0 30em;
    }
  }
}

@media (max-width: 728px) {
  .checkout_form {
    .banner_container {
      padding: 0 10px;
    }
    .banner_container:nth-of-type(3) {
      margin-bottom: 2em !important;
    }
  }
}
