//scss import
@use "./partials/variables";
@use "../../node_modules/bootstrap/scss/bootstrap.scss";

//note to ryan: code above is what i changed
html {
  box-sizing: border-box;
  font-size: 16px;
}

#product_searchbar {
  max-height: 1.225em !important;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

//userData

// button styles

button {
  color: variables.$primary_black;
  z-index: 1;
}
.auth_btn_container {
  height: 80px;
  width: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;

  .auth_btn {
    border: 1px solid variables.$primary_black;
    background: rgba(variables.$primary_black, 1);
    color: variables.$primary_white;
    height: auto;
    width: auto;
    font-size: variables.$p_sm_font_size;
    text-align: center;
    border-radius: 10px;
    padding: 0.225em 1em;
  }
}

@media (max-width: 529px) {
  .auth_btn_container {
    width: 100px;
    height: 50px;
    position: relative;
  }
}

//defaut font styles
p,
label {
  letter-spacing: -0.5px;
  font-weight: variables.$font_weight_regular;
}
::placeholder {
  letter-spacing: -0.5px;
  font-weight: variables.$font_weight_thin;
  font-size: variables.$p_font_size;
}

a {
  text-decoration: none;
  color: variables.$primary_black;
}

h5 {
  font-size: variables.$h5_font_size;
  font-weight: variables.$font_weight_mediem;
  letter-spacing: variables.$h5_letter_spacing;
}

//animation styles
.ancor_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;

  span.underline {
    width: 0%;
    height: 1px;
    background-color: variables.$primary_gray;
    transition: all 300ms;
  }
}

.ancor_container:hover {
  span.underline {
    width: 100%;
  }
}

//list syles
ol,
ul {
  list-style: none;
}

//information styles
.error_message {
  background: rgba(variables.$error_red, $alpha: 0.8);
  max-width: fit-content;
  padding: 2px 8px;
  border-radius: 10px;
  font-size: variables.$p_sm_font_size;
}

//inpute default styles
.form-control {
  background-color: variables.$primary_white;
  outline: none;
}

input.form-control:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

textarea {
  resize: none;
}

#mail-link {
  text-decoration: underline;
  color: #0044cc;
}

//import font
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.boundry_container {
  background-color: rgba(variables.$primary_black, 0.2);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
  z-index: 99;

  .procced_to_check_out_container {
    display: flex;
    flex-direction: column;
    background-color: rgba(variables.$primary_white, 1);
    max-width: 400px;
    width: 100%;
    height: min-content;
    border: solid 1px rgba(variables.$primary_black, 0.3);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: fixed;
    z-index: 10;
    right: 0;

    top: 80px;

    .procced_header_container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0.5em 1em;
      width: 100%;
      border-bottom: solid 1px rgba(variables.$primary_black, 0.2);
    }

    .procced_detail_container {
      display: flex;
      width: 100%;
      padding: 0.5em 1em;
      border-bottom: solid 1px rgba(variables.$primary_black, 0.2);

      .procced_item_image {
        width: 50%;
        height: 200px;
      }
      .procced_content_container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 0 0 0.5em;

        .procced_item_name {
          font-size: variables.$p_font_size;
          font-weight: variables.$font_weight_mediem;
          margin-bottom: 2em;
        }
      }
    }
    .procced_button_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: 8px;
      padding: 0.5em 1em;

      a {
        width: 80%;
        text-align: center;
        background-color: rgba(variables.$primary_black, 1);
        color: rgba(variables.$primary_white, 1);
        font-weight: variables.$font_weight_semi_bold;
        padding: 0.5em 0;
      }
      a:nth-of-type(1) {
        width: 80%;
        text-align: center;
        background-color: rgba(variables.$primary_green, 1);
        color: rgba(variables.$primary_black, 1);
      }
    }
  }
}

//product card
.product_card {
  display: flex;
  padding: 8px;
  max-width: calc(25% - 20px);
  width: 100%;
  height: auto;
  position: relative;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

  a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;

    .image_container {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: auto;
      height: 100%;
      position: relative;
      flex-shrink: 1;

      .product_image {
        height: auto;

        width: 100%;
        cursor: pointer;
      }
    }

    .content_container {
      display: flex;
      flex-direction: column;
      gap: 4px;

      position: relative;

      .product_name {
        font-size: variables.$p_sm_font_size;
        font-weight: variables.$font_weight_mediem;
        line-height: 1.225em;
        height: 100%;
        cursor: pointer;
      }
      .product_price {
        font-size: variables.$p_sm_font_size;
        font-weight: variables.$font_weight_regular;
        transform: translateY(-4px);
      }

      button.add_to_cart_button {
        border-top: 1px solid variables.$primary_black;
        border-right: 2px solid variables.$primary_black;
        border-left: 1px solid variables.$primary_black;
        border-bottom: 2px solid variables.$primary_black;
        background-color: rgb(white, 1);
        border-radius: 0.5em;
        font-size: variables.$p_font_size;
        font-weight: variables.$font_weight_mediem;
        line-height: 1em;
        width: 100%;
      }
    }
  }
}

//carousel & carousel components

.slick-slider.slick-initialized {
  height: auto;
}

.slick-dots {
  display: none !important;
}

.slick-next,
.slick-prev {
  min-width: 40px;
  min-height: 40px;
  top: 40%;
}

.shipping_address_input {
  width: 100%;
}

#checkout {
  padding: 2em 2em;

  div {
    iframe {
      html {
        body {
          div {
            div {
              background-color: none !important;
              div {
                .App-background {
                  background-color: none !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

//banner styles

.banner {
  height: auto;
  width: 100%;
  background-color: rgba(variables.$primary_green, 0.5);
  box-sizing: content-box;
}

.home-banner-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  gap: 20px;
  margin-bottom: 2em;
  background-color: rgba(#fc8101, 0.3);

  .section_1 {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    border-top: 1px solid rgba(7, 19, 12, 0.3);
    border-right: 2px solid rgba(7, 19, 12, 0.3);
    border-bottom: 2px solid rgba(7, 19, 12, 0.3);
    border-left: 1px solid rgba(7, 19, 12, 0.3);
    box-shadow: 3px 5px 15px 0px rgba(7, 19, 12, 0.5);

    .section_1_img {
      background-image: url("../images/banner/home_main_banner.jpg");
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
    }
    .section_1_content {
      display: flex;
      flex-direction: row;
      height: 55px;
      justify-content: center;
      align-items: center;
      gap: 15px;
      background-color: variables.$primary_white;
      .text_container {
        p {
          color: #fc8101;
        }
      }
      .banner_link {
        background: #fc8101;
        padding: 4px 8px;
        border-radius: 10px;
        color: variables.$primary_white;
      }
    }
  }
}

.home-banner-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 269px;
  gap: 20px;
  margin-bottom: 2em;
  background-color: rgba(#fc8101, 0.3);
  .section_2 {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    border-top: 1px solid rgba(7, 19, 12, 0.3);
    border-right: 2px solid rgba(7, 19, 12, 0.3);
    border-bottom: 2px solid rgba(7, 19, 12, 0.3);
    border-left: 1px solid rgba(7, 19, 12, 0.3);
    box-shadow: 3px 5px 15px 0px rgba(7, 19, 12, 0.5);
    .section_2_img {
      background-image: url("../images/banner/home-main-banner2.jpg");
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
    }
    .section_2_content {
      display: flex;
      flex-direction: row;
      height: 55px;
      justify-content: center;
      align-items: center;
      gap: 15px;
      background-color: variables.$primary_white;
      .text_container {
        p {
          color: #fc8101;
          font-weight: variables.$font_weight_semi_bold;
        }
      }
      .banner_link {
        background: #fc8101;
        padding: 4px 8px;
        border-radius: 10px;
        width: 5em;
        color: variables.$primary_white;
      }
    }
  }
}

.banner.product_page_banner {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: variables.$primary_white;
  height: 150px;
}

.image_carousel_container {
  padding: 2em 0 0 0;
  height: 100%;

  div {
    height: 100%;

    div {
      height: 100%;
      div {
        .item_image {
          height: 100%;
          width: 200px;
        }
      }
    }
  }
}

.btn {
  background: variables.$primary_black;
  color: variables.$primary_white;
  font-weight: variables.$font_weight_semi_bold;
  cursor: pointer;
}

.product_card_skeleton {
  .image_skeleton {
    img {
      max-width: 400px !important;
      border: 1px solid variables.$primary_gray;
      border-radius: 0.5em;
    }
  }
  .content_container {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    .product_name {
      max-width: 400px !important;
      width: 400px !important;
      border: 1px solid variables.$primary_gray;
      border-radius: 0.5em;
    }
    .product_price {
      max-width: 400px !important;
      width: 400px !important;
      border: 1px solid variables.$primary_gray !important;
      border-radius: 0.5em !important;
    }
  }
}

#shipping-methods-admin {
  max-width: 400px !important;
  width: 400px !important;
  border: 1px solid variables.$primary_gray !important;
  border-radius: 0.5em !important;
}

@media (min-width: 1800px) {
}

@media (max-width: 1024px) {
  .slick-next,
  .slick-prev {
    display: none !important;
  }
}

@media (max-width: 728px) {
  .product_card {
    max-width: calc(50% - 24px);
    .image_container {
      .product_image {
        padding: 24px;
        height: 100%;
        width: auto;
        max-width: 300px;
      }
    }
  }

  //banner styles
  .home-banner-1 {
    .section_2 {
      display: none;
    }
  }
}
@media (max-width: 529px) {
  .product_card {
    max-width: 47%;
    .image_container {
      .product_image {
        padding: 0;
        width: auto;
        height: 100%;
      }
    }
  }
}
