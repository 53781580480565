@use "../variables";

.corporate_order_container {
  background-color: variables.$primary_white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2em 10em;
  gap: 2em;

  .logo_a {
    width: 100%;
    display: flex;
    justify-content: center;
    svg {
      margin-top: 150px;
      width: auto;
      position: relative;
      height: auto;
    }
  }

  h1 {
    width: 100%;
    border-bottom: 1px solid rgba(variables.$primary_black, 0.6);
    font-size: variables.$h4_font_size;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product_section_container {
    display: flex;
    gap: 20px;
    justify-content: space-evenly;
    width: 100%;
    flex-wrap: wrap;
    flex: 1 0 0;
    padding: 0em 0em;

    .header_container {
      display: none;
    }
  }
}

@media (min-width: 1900px) {
  .corporate_order_container {
    padding: 2em 40em !important;
  }
}

@media (max-width: 1024px) {
  .corporate_order_container {
    padding: 2em 8px !important;
  }
}
