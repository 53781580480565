@use "../variables";

.cart_page {
  max-width: 100vw;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 2em 8em;
  gap: 2em;

  .cart_logo_container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    .logo {
      margin-top: 150px;
      width: auto;
      position: relative;
      height: auto;
    }
  }

  .cart_header_container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    max-height: 200px;
    h5 {
      font-weight: variables.$font_weight_regular;
      color: variables.$primary_black;
      text-align: left;
      width: 100%;
    }
  }

  .cart_content_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 4em;
    margin: 4em 0;

    .cart_content {
      display: flex;
      flex-direction: column;
      max-width: 75%;
      .item_content {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 2em;
        align-items: flex-start;
        justify-content: flex-start;
        background-color: rgba(variables.$primary_white, 0.1);
        padding: 2em;
        border-bottom: 1px solid rgba(variables.$primary_gray, 0.2);
        div {
          width: 20%;
          .quantity_container {
            width: 100%;
            display: flex;
            flex-direction: row;
            gap: 0.255em;
            align-items: center;
            justify-content: center;
            label {
              font-size: variables.$p_font_size;
              color: rgba(variables.$primary_gray, 0.5);
              font-weight: variables.$font_weight_light;
            }
            button {
              width: 100%;
              border: none;
              background: variables.$primary_red;
              color: variables.$primary_white;
            }
          }

          .item_image_content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            max-width: 400px;
            margin-right: 2em;

            p {
              font-weight: variables.$font_weight_semi_bold;
              color: variables.$primary_black;

              width: 100%;
            }
          }

          .item_image {
            height: 100px;
            width: 100%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: left center;
            cursor: pointer;
          }
        }
        p {
          font-weight: variables.$font_weight_semi_bold;
          color: variables.$primary_black;
          text-align: left;
          margin-right: 1em;
        }
        p:nth-last-of-type(1) {
          font-weight: variables.$font_weight_regular;
          color: variables.$primary_gray;
        }
        .item_price {
          font-weight: variables.$font_weight_regular;
          font-size: variables.$p_sm_font_size;
        }
      }
    }

    .subtotal_container {
      display: flex;
      flex-direction: column;
      width: 40%;
      padding: 0 1em;
      gap: 1em;
      background-color: rgba(variables.$primary_gray, 0.05);

      .clear_cart_btn {
        font-weight: variables.$font_weight_regular;
      }

      .check_out_btn {
        background-color: variables.$primary_green !important;
        color: variables.$primary_black;
        font-weight: variables.$font_weight_semi_bold;
      }
    }
  }
}

@media (min-width: 1900px) {
  .cart_page {
    padding: 2em 30em;
  }
}

@media (max-width: 748px) {
  .cart_page {
    padding: 2em 20px;

    .cart_logo_container {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
    }
    .cart_content_container {
      flex-direction: column;
      gap: 2em;
      margin: 0;
      .cart_content {
        max-width: 100%;
        .item_content {
          flex-direction: row;
          width: 100%;
          padding: 8px 0;

          div {
            width: 50%;

            select {
              width: 150px;
            }
            .quantity_container {
              display: flex;
              flex-direction: row;
              gap: 4px;
            }
          }

          .item_image_content {
            align-items: center;
            justify-content: center;
            margin: 0;
            width: 100%;
          }
        }
      }
      .subtotal_container {
        width: 100%;
      }
    }
  }
}
