@use "../variables";

.nav_container {
  max-width: 100vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 2;
  transition: all 0.3s;
  background-color: variables.$primary_white;

  .info_nav_container {
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;

    a {
      font-size: variables.$p_sm_font_size;
      font-weight: variables.$font_weight_light;
    }
  }

  &.show_nav {
    overflow: visible;
    transform: translateY(0px);
    height: auto;
  }

  &.hide_nav {
    overflow: hidden;
    transform: translateY(-700px);
  }

  .nav_content {
    display: flex;
    flex-direction: row;

    width: 100%;
    align-items: center;
    justify-content: space-between;
    min-height: 50px;
    background-color: rgba(variables.$primary_white, 1);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    z-index: 2;

    .country_selector {
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .logo_black {
      height: 100%;
      width: auto;
      cursor: pointer;
      margin-left: 1em;
    }

    .shopping_bag_container {
      width: auto;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      height: 75px;

      position: relative;
      margin-right: 20px;
      z-index: 1;

      .shopping_bag_content {
        top: 25px;
        height: 100%;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 1em;

        #text {
          height: 100%;
          cursor: pointer;
          height: 100%;
        }
        .shopping_bag {
          cursor: pointer;
          height: 100%;
        }
      }
    }

    .content_column_container {
      display: flex;
      align-items: center;
      height: 80px;
      padding: 0 1em;

      flex-direction: row;
      position: relative;
      .x_icon {
        display: none;
      }

      .list_header_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        cursor: pointer;
        padding: 4px 12px;
        max-width: 100%;
        z-index: 1;
        border-right: 2px solid
          rgba($color: variables.$primary_black, $alpha: 0.5);
        background-color: rgba(variables.$primary_white, 1);

        h5 {
          display: flex;
          align-items: center;
          justify-content: center;
          width: fit-content;
          font-weight: variables.$font_weight_mediem;
          text-align: center;
          font-size: variables.$p_font_size;
          letter-spacing: -0.5px;
          height: 80px;
          color: variables.$primary_black;

          width: 100%;
        }
      }

      .hidden {
        width: auto;
        display: none;
        gap: 4px;
        height: auto;
        padding-bottom: 0.5em;
        // box-shadow: 4px 0px 10px rgb(variables.$primary_black, 0.1);
        transition: all 0.3s;
      }

      .show {
        display: flex;
        max-width: 1000px;
        width: 100%;
        min-width: 600px;
        position: absolute;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: row;
        top: 79px;

        z-index: 2;
        gap: 4px;
        transition: all 0.3s;
        background: rgba(variables.$primary_white, 1);
        border-right: 1px solid
          rgba($color: variables.$primary_black, $alpha: 0.5);
        border-bottom: 1px solid
          rgba($color: variables.$primary_black, $alpha: 0.5);
        border-left: 1px solid
          rgba($color: variables.$primary_black, $alpha: 0.5);
        flex-wrap: wrap;

        .list_background {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: start;
          background: rgba(variables.$primary_white, 0.2);
          max-height: 400px;
          flex-wrap: wrap;
          gap: 24px;
          padding: 1em 1em 1em 1em;

          .list_container {
            display: flex;

            flex-direction: column;
            width: auto;
            flex-wrap: wrap;

            h5 {
              font-weight: variables.$font_weight_semi_bold;
              width: 100%;
              letter-spacing: -1.5px;
            }

            li {
              position: relative;
              display: flex;
              flex-direction: column;

              cursor: pointer;
              transition: transform 0.3s ease-in-out;

              a {
                display: flex;
                width: auto;
                height: auto;
                text-align: start;
                font-weight: variables.$font_weight_mediem;
                font-size: variables.$p_sm_font_size;
                position: relative;
                z-index: 1;
                border-bottom: 1px solid
                  rgba($color: variables.$primary_black, $alpha: 0.5);
              }

              span.underline {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                height: 2px;
                background-color: black;
                transition: width 0.3s ease-in-out;
              }

              &:hover {
                transform: translateX(8px);

                .underline {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
    .hamburger_menu {
      display: none;
      flex-direction: column;
      justify-content: space-between;
      height: 25px;
      width: 30px;
      cursor: pointer;
      z-index: 10;

      .hamburger_line {
        width: 100%;
        height: 2px;
        background-color: black;
        transition: all 0.3s ease-in-out;

        &:nth-child(1) {
          transform: translateY(0);
        }

        &:nth-child(2) {
          transform: translateY(5px);
        }

        &:nth-child(3) {
          transform: translateY(10px);
        }
      }
    }

    .buttons_container {
      width: 100%;
      display: flex;
      justify-content: space-evenly;

      align-items: center;
      gap: 4px;
      height: 80px;

      a {
        // border-top: 1px solid variables.$primary_black;
        // border-right: 2px solid variables.$primary_black;
        // border-left: 1px solid variables.$primary_black;
        // border-bottom: 2px solid variables.$primary_black;

        padding: 4px 8px;
        border-radius: 10px;

        height: fit-content;
        text-align: center;
      }
      .under_50,
      .under_100,
      .under_150 {
        // border-top: 1px solid variables.$primary_black;
        // border-right: 2px solid variables.$primary_black;
        // border-left: 1px solid variables.$primary_black;
        // border-bottom: 2px solid variables.$primary_black;
        border-radius: 20px;
        padding: 0.5em 1em;
        width: max-content;
        text-align: center;
        background-color: rgba(variables.$primary_green, 0);
      }
      .sort_filter_container {
        display: flex;
        flex-direction: column;
        .sort_filter_button {
          border: none;
          background: none;
          padding: 0;
        }
        span.underline {
          width: 100%;
          height: 1px;
          background: variables.$primary_black;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .nav_container {
    .nav_content {
      padding: 0 20px 0 0;

      .logo_black {
        display: none;
      }

      .buttons_container {
        display: none;
      }

      .shopping_bag_container {
        height: 50px;
        position: absolute;
        width: 50px;
        top: 400px;
        right: -20px;

        .shopping_bag_content {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          background: rgba(variables.$primary_green, 0.5);
          padding: 4px;
          border-left: 1px solid
            rgba($color: variables.$primary_black, $alpha: 1);
          border-bottom: 1px solid
            rgba($color: variables.$primary_black, $alpha: 1);
          border-top: 1px solid
            rgba($color: variables.$primary_black, $alpha: 1);

          .shopping_bag {
            padding: 0 0.5em;
            height: 50px;
            margin-right: 0px;
            position: relative;
            top: 0;
            width: 100%;
          }
        }
        .content_column_container {
          max-width: 125px;
          width: 100%;
          display: flex;
          align-items: center;
          padding: 0 1em;
          flex-wrap: wrap;
          flex-direction: column;
          position: relative;
          min-height: 200px;

          .list_header_container {
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            cursor: pointer;
            padding: 4px 8px;
            text-align: center;
            width: 40%;
            // border-top: 1px solid #07130c;
            // border-right: 3px solid #07130c;
            // border-left: 1px solid #07130c;
            // border-bottom: 3px solid #07130c;
            // background-color: rgba(249, 232, 191, 1);
            z-index: 1;

            h5 {
              display: flex;
              align-items: center;
              justify-content: center;

              font-weight: variables.$font_weight_regular;
              text-align: center;
              font-size: variables.$p_sm_font_size;
              letter-spacing: -0.5px;
              height: 70px;
              color: variables.$primary_black;
            }
          }
        }
      }
      .logo_black {
        display: none;
      }
      .buttons_container {
        .under_50,
        .under_150,
        .under_100 {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 786px) {
  .nav_container {
    height: 80px;
    .nav_content {
      height: 80px;

      .content_column_container {
        padding: 0;
        .list_header_container {
          height: 80px;
          padding: 4px 0 0 0;
          width: 100%;
        }
        .list_header_container {
          h5 {
            font-size: variables.$p_sm_font_size;
          }
        }
      }

      .nav_items {
        width: 100%;
        justify-content: space-evenly;
        .nav_item {
          width: auto;
          svg {
            max-height: 15px;
          }
          .ancor_container {
            a.ancor_tag {
              font-size: variables.$p_sm_font_size;
              padding: 0;
            }
          }
        }
        .search_icon {
          width: min-content;
        }
      }
      .shipping_address_input {
        display: none;
      }
    }
    .category_nav {
      ul:nth-of-type(2) {
        li {
          width: 20em;
          padding: 0;
          a {
            font-size: variables.$p_sm_font_size;
          }
        }
      }
    }
  }
}

@media (max-width: 529px) {
  .nav_container {
    #buttom_nav_box {
      display: none !important;
    }

    .logo_black {
      display: flex !important;
      width: 50% !important;
    }
    .nav_content {
      .shopping_bag_container {
        left: 7em;
      }
      a {
        width: 40%;
        .logo_black {
          display: flex;
          width: 80% !important;
        }
      }

      .shopping_bag_container {
        height: 50px;
        position: none;

        width: 100px;
        top: -10px;
        right: 0;
        margin-right: 4em;

        .shopping_bag_content {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          background: none;
          padding: 4px;
          border: none;

          .shopping_bag {
            padding: 0;
            height: 50px;
            width: auto;
            margin-right: 0px;
            position: relative;
            top: 0;
          }
        }
      }
      .content_column_container {
        flex-direction: column;
        top: 80px;
        padding: 0;
        background-color: rgba(variables.$primary_white, 1);
        width: 100vw;
        position: absolute;

        .list_header_container {
          height: 80px;
          padding: 4px 4px;
          width: 100%;
          h5 {
            font-size: variables.$p_font_size;
          }
        }
        .list_header_container:nth-of-type(4) {
          border-bottom: 1px solid rgba(variables.$primary_black, 0.5);
        }

        .list_container {
          a {
            width: 150px !important;
            border-bottom: solid 1px rgba(variables.$primary_black, 0.5);
          }
        }

        .show {
          top: 0;
          min-width: 100vw;
        }
        .x_icon {
          left: 0;
          display: inline;
          position: absolute;
          z-index: 10;
        }
      }
      .hamburger_menu {
        display: none;
        position: relative;
        width: 40px;
        top: 20px;
      }
      .nav_items {
        width: 100%;
        justify-content: space-evenly;
        .nav_item {
          width: auto;
          svg {
            max-height: 15px;
          }
          .ancor_container {
            a.ancor_tag {
              font-size: variables.$p_sm_font_size;
              padding: 0;
            }
          }
        }
        .search_icon {
          width: min-content;
        }
      }
      .shipping_address_input {
        display: none;
      }
    }

    .category_nav {
      ul:nth-of-type(1) {
        display: none;
      }

      ul:nth-of-type(2) {
        padding: 1em 0.5em;
        justify-content: space-between;
        li {
          width: 100%;

          a {
            font-size: variables.$p_sm_font_size;
            text-transform: lowercase;
          }
        }
      }
    }
  }
}
