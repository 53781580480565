.corporate_order_container {
  background-color: #fcfaf7;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2em 10em;
  gap: 2em;
}
.corporate_order_container .logo_a {
  width: 100%;
  display: flex;
  justify-content: center;
}
.corporate_order_container .logo_a svg {
  margin-top: 150px;
  width: auto;
  position: relative;
  height: auto;
}
.corporate_order_container h1 {
  width: 100%;
  border-bottom: 1px solid rgba(7, 19, 12, 0.6);
  font-size: clamp(16px, 5vw, 36px);
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.corporate_order_container .product_section_container {
  display: flex;
  gap: 20px;
  justify-content: space-evenly;
  width: 100%;
  flex-wrap: wrap;
  flex: 1 0 0;
  padding: 0em 0em;
}
.corporate_order_container .product_section_container .header_container {
  display: none;
}

@media (min-width: 1900px) {
  .corporate_order_container {
    padding: 2em 40em !important;
  }
}
@media (max-width: 1024px) {
  .corporate_order_container {
    padding: 2em 8px !important;
  }
}/*# sourceMappingURL=corporateOrderStyles.module.css.map */