@use "../variables";
.landing_page_container {
  display: flex;

  background-color: variables.$primary_white;
  max-width: 100vw;
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 5vh 0;
  gap: 1em;
  .logo_white {
    margin-top: 150px;
    width: auto;
    position: relative;
    height: auto;
  }

  .landing_header {
    text-align: center;
    margin-bottom: 24px;
  }

  .input_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: variables.$primary_green;
    padding: 20px;
    gap: 10px;

    .text_container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 80vw;

      height: 100%;

      h5,
      h1 {
        width: min(70vw, 50vw);
        font-size: variables.$h4_font_size;
        font-weight: variables.$font_weight_semi_bold;
        text-align: start;
        padding: 0 12px 12px 0px;
        display: flex;
        justify-items: center;
        align-items: center;
      }
    }

    .image_container {
      width: 100%;
      .banner_image {
        background: url("../../../images/landingPage/landing-page.JPEG");
        background-position: center center;
        height: min(300px, 50vw);
        background-size: cover;
      }
    }

    .shipping_address_input {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: min(90vw, 500px);

      form {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 4px;
        flex-direction: column;
        input {
          font-size: variables.$p_font_size;
          border-top: 1px solid variables.$primary_black;
          border-right: 3px solid variables.$primary_black;
          border-left: 1px solid variables.$primary_black;
          border-bottom: 3px solid variables.$primary_black;
          display: flex;
          height: clamp(25px, 5vw, 50px);
          width: min(400px, 30vw);
          padding: 10px;
          border-radius: 5px;
        }

        input::placeholder {
          font-size: 1em;

          text-align: start;
          position: absolute;
        }
        svg {
          position: relative;
          right: 25px;
          cursor: pointer;
        }

        .input_button {
          padding: min(2em, 0.6em) 2em;
          background: variables.$primary_black;
          color: variables.$primary_white;
          height: 100%;
          font-weight: variables.$font_weight_semi_bold;
          border: none;
          border-radius: 5px;
        }
      }
      p.error_or_success {
        width: 100%;

        background-color: red;
      }
    }
  }

  .product_container {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 20px);

    gap: 1em;
  }
  h1 {
    font-weight: variables.$font_weight_semi_bold;
    text-align: center;
    font-size: variables.$h1_font_size;
    padding: 24px 20px 0 20px;
  }
}

@media (min-width: 1800px) {
  .landing_page_container {
    .input_container {
      height: 100%;

      .text_container {
        width: 100%;
        justify-content: center;
        h5 {
          max-width: 100%;
        }
      }

      .image_container {
        width: 50%;
        .banner_image {
          height: 350px;
        }
      }
    }

    .product_container {
      display: flex;
      flex-wrap: wrap;
      width: 75%;
      justify-content: center;

      h1 {
        font-weight: variables.$font_weight_semi_bold;

        font-size: variables.$h1_font_size;
        padding: 24px 20px 0 20px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .landing_page_container {
    .input_container {
      flex-direction: column;

      .text_container {
        h5,
        h1 {
          width: 100%;
        }
      }

      .shipping_address_input {
        form {
          input {
            min-width: 80vw;
          }
        }
      }

      .banner_image {
        display: none;
      }
    }
  }
}

@media (max-width: 724px) {
  .landing_page_container {
    .input_container {
      padding: 2em 20px;
      flex-direction: column;

      .text_container {
        .shipping_address_input {
          form {
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
          }
        }
      }
    }
  }
}
