.banner {
  height: 350px;
  width: 100%;
  box-sizing: content-box;
}

.birthday_banner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 269px;
  gap: 20px;
  margin-bottom: 2em;
  background-color: none;
}
.birthday_banner .section_1 {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  border-top: 1px solid rgba(7, 19, 12, 0.3);
  border-right: 3px solid rgba(7, 19, 12, 0.3);
  border-bottom: 3px solid rgba(7, 19, 12, 0.3);
  border-left: 1px solid rgba(7, 19, 12, 0.3);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}
.birthday_banner .section_1 .section_1_img {
  background-image: url("../../../images/banner/birthdaycake.jpg");
  width: 30%;
  height: 269px;
  background-size: cover;
  background-position: center;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.birthday_banner .section_1 .section_1_content {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 60%;
  justify-content: center;
  align-items: center;
  gap: 15px;
  background-color: #fcfaf7;
}
.birthday_banner .section_1 .section_1_content .text_container p {
  color: #07130c;
}
.birthday_banner .section_1 .section_1_content .banner_link {
  width: 20%;
  background: #ec85b4;
  padding: 4px 8px;
  border-radius: 10px;
  color: #fcfaf7;
  font-weight: 600;
}

.occasion_banner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 235px;
  gap: 20px;
  margin-bottom: 2em;
  background-color: none;
}
.occasion_banner .section_1 {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  border-top: 1px solid rgba(7, 19, 12, 0.3);
  border-right: 3px solid rgba(7, 19, 12, 0.3);
  border-bottom: 3px solid rgba(7, 19, 12, 0.3);
  border-left: 1px solid rgba(7, 19, 12, 0.3);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}
.occasion_banner .section_1 .section_1_img {
  background-image: url("../../../images/banner/halloween-occasion.jpg");
  width: 60%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.occasion_banner .section_1 .section_1_content {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 40%;
  justify-content: center;
  align-items: center;
  gap: 15px;
  background-color: #fcfaf7;
}
.occasion_banner .section_1 .section_1_content .text_container p {
  color: #fc8101;
  font-weight: 600;
}
.occasion_banner .section_1 .section_1_content .banner_link {
  width: 5em;
  background: #fc8101;
  padding: 4px 8px;
  border-radius: 10px;
  color: #fcfaf7;
}

.explore_banner {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: auto;
  gap: 20px;
  margin-bottom: 2em;
  background-color: none;
}
.explore_banner .header_container {
  width: 100%;
  height: auto;
}
.explore_banner .header_container .header {
  font-size: clamp(24px, 5vw, 54px);
  text-align: center;
}
.explore_banner .explore_list {
  display: flex;
  flex-direction: row;
  height: 25em;
  width: 100%;
  background-image: url("../../../images/banner/explore-gifts.jpg");
  background-position: 50% 50%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}
.explore_banner .explore_list .list_item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 100%;
}
.explore_banner .explore_list .list_item a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.explore_banner .explore_list .list_item a .item_img {
  width: 75%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  filter: drop-shadow(3px 5px 14px rgba(7, 19, 12, 0.5));
}
.explore_banner .explore_list .list_item .season {
  background-image: url("../../../images/banner/halloween-btn.png");
}
.explore_banner .explore_list .list_item .wedding {
  background-image: url("../../../images/banner/wedding-btn.png");
}
.explore_banner .explore_list .list_item .birthday {
  background-image: url("../../../images/banner/birthday-btn.png");
}
.explore_banner .explore_list .list_item .gourmet {
  background-image: url("../../../images/banner/gourmet-btn.png");
}

.taste_canada_banner {
  width: 100%;
  height: 269px;
  display: flex;
  flex-direction: row;
}
.taste_canada_banner .img_container {
  display: flex;
  height: 269px;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.taste_canada_banner .img_container .left_branch_img {
  background-image: url("../../../images/banner/left-maple-branch.png");
  height: 100%;
  background-position: top;
  background-repeat: no-repeat;
  width: 100%;
  background-size: contain;
}
.taste_canada_banner .left_leaf_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 5%;
  height: 100%;
}
.taste_canada_banner .left_leaf_container .left_top_leaf_img {
  background-image: url("../../../images/banner/left-top-leaf.png");
  height: 100%;
  background-repeat: no-repeat;
  width: 100%;
  background-size: contain;
  background-position: 0 10%;
}
.taste_canada_banner .left_leaf_container .left_bottom_leaf_img {
  background-image: url("../../../images/banner/left-bottom-leaf.png");
  height: 100%;
  background-repeat: no-repeat;
  width: 100%;
  background-size: contain;
  background-position: 0 90%;
}
.taste_canada_banner .right_leaf_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 5%;
  height: 100%;
}
.taste_canada_banner .right_leaf_container .right_top_leaf_img {
  background-image: url("../../../images/banner/right-top-leaf.png");
  height: 100%;
  background-repeat: no-repeat;
  width: 100%;
  background-size: contain;
  background-position: 0 10%;
}
.taste_canada_banner .right_leaf_container .right_bottom_leaf_img {
  background-image: url("../../../images/banner/right-bottom-leaf.png");
  height: 100%;
  background-repeat: no-repeat;
  width: 100%;
  background-size: contain;
  background-position: 0 90%;
}
.taste_canada_banner .img_container {
  display: flex;
  height: 350px;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.taste_canada_banner .img_container .right_branch_img {
  background-image: url("../../../images/banner/right-maple-branch.png");
  height: 100%;
  background-position: top;
  background-repeat: no-repeat;
  width: 100%;
  background-size: contain;
}
.taste_canada_banner .content_container {
  height: 100%;
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.taste_canada_banner .content_container h1 {
  text-align: center;
}
.taste_canada_banner .content_container h1 span {
  color: #f04141;
}
.taste_canada_banner .content_container a {
  background: #f04141;
  padding: 4px 8px;
  border-radius: 10px;
  color: #fcfaf7;
}

.category_banner {
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}
.category_banner .img_container {
  background-image: url("../../../images/banner/category-banner.png");
  width: 100%;
  height: 0;
  padding-top: 37.65%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.category_banner h2 {
  position: absolute;
  font-size: clamp(24px, 5vw, 54px);
  font-weight: 600;
  z-index: 1;
  margin-right: 4em;
  left: 60%;
}

.for_her_banner1 {
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}
.for_her_banner1 .img_container {
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.for_her_banner2 {
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}
.for_her_banner2 .img_container {
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.ready_for_shopping_banner {
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}
.ready_for_shopping_banner .img_container {
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.for_kids_banner {
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}
.for_kids_banner .img_container {
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.all_banner {
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}
.all_banner .img_container {
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

@media (max-width: 728px) {
  .birthday_banner {
    height: 269px;
  }
  .birthday_banner .section_1 {
    flex-direction: column;
  }
  .birthday_banner .section_1 .section_1_img {
    width: 100%;
    height: 400px;
    background-position: 0% 45%;
  }
  .birthday_banner .section_1 .section_1_content {
    width: 100%;
    height: 150px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }
  .birthday_banner .section_1 .section_1_content .text_container p {
    font-size: clamp(14px, 5vw, 12px);
  }
  .occasion_banner {
    height: 235px;
  }
  .occasion_banner .section_1 {
    flex-direction: column;
  }
  .occasion_banner .section_1 .section_1_img {
    width: 100%;
    height: 235px;
    background-position: 0% 45%;
  }
  .occasion_banner .section_1 .section_1_content {
    width: 100%;
    height: 235px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
  .occasion_banner .section_1 .section_1_content .text_container p {
    font-size: clamp(14px, 5vw, 12px);
  }
  .explore_banner .explore_list {
    height: 10em;
  }
  .explore_banner .explore_list .list_item {
    padding: 0 0.5em;
  }
  .explore_banner .explore_list .list_item a .item_img {
    width: 100%;
    height: 100%;
  }
  .taste_canada_banner {
    height: 169px;
  }
  .taste_canada_banner .img_container {
    height: 169px;
  }
}
@media (max-width: 728px) {
  .explore_banner .explore_list {
    height: 15em;
  }
  .explore_banner .explore_list .list_item {
    padding: 0 0.5em;
  }
  .explore_banner .explore_list .list_item a .item_img {
    width: 100%;
    height: 100%;
  }
}/*# sourceMappingURL=banner.module.css.map */